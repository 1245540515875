<template>
	
	<form name="pllenty-chat-login-form" class="pllenty-chat-form pllenty-chat-login-form" @submit.prevent="processForm">
		
		<layout-div name="content">
			
			<scroller>
				
				<alert v-model="errorMessage" type="error" auto-close="true" />
				
				<transition name="fade-in">
					
					<connecting v-if="connecting" :chat-info="chatInfo" />
					
					<form-fields v-else v-model="chatRequest" :chat-info="chatInfo" />
					
				</transition>
			
			</scroller>
			
		</layout-div>
		
		<sub-content-btn btn="start" :disabled="connecting">
			<template v-if="!connecting">
				{{ loginBtn }}
			</template>
			<spinner v-else :size="1"></spinner>
		</sub-content-btn>
		
	</form>

</template>

<script>
	
	import { createRequest } from '../../api';
	import { Alert, LayoutDiv, Scroller, Spinner, SubContentBtn } from '../../elements/components';
	import FormFields from './FormFields';
	import Connecting from './Connecting';
	
	export default {
		
		components: {
			Alert,
			LayoutDiv,
			Scroller,
			SubContentBtn,
			FormFields,
			Connecting,
			Spinner
		},
		
		props: {
			
			chatInfo: {
				type: Object,
				required: true
			},
			
			value: {
				type: Object,
				default: () => {}
			}
			
		},
		
		data() {
			
			return {
				chatRequest: {
					email: '',
					firstName: '',
					lastName: '',
					phone: ''
				},
				connecting: false,
				errorMessage: null
			};
		
		},
		
		computed: {
			
			loginBtn() {
				
				return 'Start';
				
			}
			
		},
		
		watch: {
			
			'chatInfo.config.testMode'(val) {
				
				if (val === true || val === 'Request') {
					
					Object.assign(this.chatRequest, {
						email: 'johndoe@example.com',
						firstName: 'John',
						lastName: 'Doe',
						phone: '6475550123'
					});
					
				}
				
			}
			
		},
		
		created() {
			
			if (this.$safeCookies.isKey('requestInfo')) {
				
				this.chatRequest = this.$safeCookies.get('requestInfo');
				
			}
			
			if (this.value) { // chatSession (v-model) is set?
				
				this.connecting = true;
				
			}
			
		},
		
		methods: {
			
			processForm() {
				
				this.connecting = true;
				
				this.$safeCookies.set('requestInfo', this.chatRequest);
				
				createRequest(this.chatInfo.companyId, this.chatRequest)
					.then(res => {
						
						this.$safeCookies.set('chatSession', res.data);
						
						// UPDATE V-MODEL (which is chatSession)
						this.$emit('input', res.data);
						
					})
					.catch(err => {
						
						this.errorMessage = err.response.data.errorMessage.en;
						this.connecting = false;
						
					});
				
			}
			
		}
		
	};

</script>

<style scoped lang="scss">
	
	.fade-in-enter-active {
		transition: opacity .5s;
	}
	
	.fade-in-enter {
		opacity: 0;
	}

</style>
