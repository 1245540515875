<template>

	<div class="pllenty-chat-countdown">
		{{ count }}
	</div>

</template>

<script>

	export default {

		data() {

			return {
				count: null,
				interval: null
			};

		},

		watch: {

			count(val) {

				if (val === 0) {

					clearInterval(this.interval);

					this.$emit('whenCountIsZero');

				}

			}

		},

		mounted() {

			let self = this;

			// set the starting count

			this.$slots.default.forEach(vnode => {

				if (vnode.text) {

					self.count = parseFloat(vnode.text);

				}

			});

			// start counting

			this.interval = window.setInterval(() => {

				--self.count;

			}, 1000);

		}

	};

</script>

<style scoped lang="scss">

	.#{$namespace}-countdown {
		display: inline-block;
		font-size: $reset-countdown-font-size;
		color: $reset-countdown;
		margin: $reset-countdown-margin;
	}

</style>
