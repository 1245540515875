<template>
	
	<div :class="divClasses()">
		
		<label
			v-if="fieldType !== 'select' && field.label"
			:for="name"
			class="sr-only"
		>{{ field.label }}</label>
		
		<input
			v-if="fieldType === 'money'"
			v-model.trim="value[name]"
			type="number"
			:name="name"
			:placeholder="field.label"
			:required="field.required"
			:autocomplete="field.autocomplete"
			:x-autocompletetype="field['x-autocompletetype'] || field.autocomplete"
			:disabled="isDisabled(field)"
			@change="$emit('change')"
		/>
		
		<input
			v-else-if="['email', 'text', 'tel'].includes(fieldType)"
			v-model.trim="value[name]"
			:type="fieldType"
			:name="name"
			:placeholder="field.label"
			:required="field.required"
			:autocomplete="field.autocomplete"
			:x-autocompletetype="field['x-autocompletetype'] || field.autocomplete"
			:disabled="isDisabled(field)"
			@change="$emit('change')"
		/>
		
		<textarea
			v-else-if="fieldType === 'textarea'"
			v-model.trim="value[name]"
			:name="name"
			:placeholder="field.label"
			:required="field.required"
			:autocomplete="field.autocomplete"
			:x-autocompletetype="field['x-autocompletetype'] || field.autocomplete"
			:disabled="isDisabled(field)"
			@change="$emit('change')"
		></textarea>
		
		<custom-select
			v-else-if="fieldType === 'select'"
			ref="select"
			v-model="value[name]"
			:options="fieldOptions"
			:name="name"
			:placeholder="field.label"
			:required="field.required"
			:disabled="isDisabled(field)"
			:autocomplete="field.autocomplete"
			:x-autocompletetype="field['x-autocompletetype'] || field.autocomplete"
			@change="$emit('change')"
		></custom-select>
		
		<!-- eslint-disable vue/no-v-html -->
		<div
			v-else-if="fieldType === 'html'"
			:class="getFieldClasses(fieldType, field)"
			v-html="sanitize(field.content)"
		></div>
		<!-- eslint-enable vue/no-v-html -->
		
	</div>
	
</template>

<script>
	
	import sanitizeHtml from 'sanitize-html';
	import CustomSelect from './CustomSelect';
	
	export default {
		
		components: {
			CustomSelect
		},
		
		props: {
			value: {
				type: [String, Number, Object, Boolean],
				required: true
			},
			name: {
				type: String,
				required: true
			},
			field: {
				type: Object,
				required: true
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		
		computed: {
			
			fieldType() {
				
				if (this.field.options && Object.keys(this.field.options).length) {
					
					return this.field.type || 'select';
					
				}
				
				return this.field.type || 'text';
				
			},
			
			fieldOptions() {
				
				if (!this.field.options) {
					
					return [];
					
				}
				
				if (!this.optionsNeedConvertion()) {
					
					return this.field.options;
					
				}
				
				let arrayOfOptions = [];
				
				for (let prop in this.field.options) {
					
					if (this.field.options.hasOwnProperty(prop)) {
						
						arrayOfOptions.push({
							value: prop,
							text: this.field.options[prop]
						});
						
					}
					
				}
				
				return arrayOfOptions;
				
			}
			
		},
	
		methods: {
			
			sanitize(val) {
				
				return sanitizeHtml(val, this.$sanitizeOptions);
				
			},
			
			getFieldClasses(fieldType, field) {
				
				return {
					'pllenty-chat-form-custom-html': (fieldType === 'html'),
					'pllenty-chat-disabled': field.disabled || this.disabled
				};
				
			},
			
			isDisabled(field) {
				
				return (field.disabled || this.disabled);
				
			},
			
			optionsNeedConvertion() {
				
				for (let prop in this.field.options) {
					
					if (this.field.options.hasOwnProperty(prop)) {
						
						return !(
							this.field.options[prop].hasOwnProperty('value')
							&& this.field.options[prop].hasOwnProperty('text')
						);
						
					}
					
				}
				
				return false;
				
			},
			
			divClasses() {
				
				let classes = [
					'pllenty-chat-form-field',
					'pllenty-chat-form-field-' + this.fieldType
				];
				
				if (this.field.required) {
					
					classes.push('pllenty-chat-required');
					
				}
				
				return classes.join(' ');
				
			}
			
		}
		
	};

</script>

<style scoped lang="scss">
	
	/deep/ .#{$namespace}-form-custom-html {
		padding: 0 5px;
	}
	
	.sr-only {
		position: absolute;
		width: 1px;
		height: 1px;
		padding: 0;
		margin: -1px;
		overflow: hidden;
		clip: rect(0,0,0,0);
		border: 0;
	}
	
</style>
