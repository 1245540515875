<template>
	
	<div :class="classes">
		
		<slot></slot>
	
	</div>

</template>

<script>
	
	export default {
		
		props: {
			name: {
				type: String,
				default() {
					
					return 'div';
					
				}
			}
		},
		
		computed: {
			
			ref() {
				
				return this.name || '';
				
			},
			
			classes() {
				
				return 'pllenty-chat-' + this.ref;
				
			}
			
		}
		
	};

</script>

<style scoped lang="scss">

</style>
