<template>
	
	<form novalidate name="pllenty-chat-form" class="pllenty-chat-form pllenty-chat-form-form" @submit.prevent="saveData">
		
		<layout-div name="content" :class="{'pllenty-chat-disabled': !userIsInChannel}">
			
			<scroller>
				
				<layout-div name="content-scroll-inner" :class="scrollInnerClasses">
					
					<form-field
						v-for="(field, id) in formFields"
						:key="id"
						v-model="chatInfo.formData"
						:field="field"
						:name="id"
						:disabled="chatInfo.isHubAgent"
					/>
				
				</layout-div>
			
			</scroller>
			
			<layout-div name="inside-alerts">
				
				<alert
					v-model="errorMessage"
					type="error"
					:auto-close="false"
					@enter="startAlertResize"
					@after-enter="stopAlertResize"
					@leave="startAlertResize"
					@after-leave="stopAlertResize"
				/>
				
			</layout-div>
			
		</layout-div>
		
		<sub-content-btn v-if="!chatInfo.isHubAgent" btn="form" :disabled="sendingForm || chatInfo.isHubAgent">
			<template v-if="!sendingForm">
				Submit
			</template>
			<spinner v-else :size="1" />
		</sub-content-btn>
		
		<layout-div v-else name="sub-content">
		
		</layout-div>
	
	</form>

</template>

<script>
	
	import AlertResize from '../../elements/mixins/AlertResize';
	import { Alert, FormField, LayoutDiv, Scroller, Spinner, SubContentBtn } from '../../elements/components';
	import { postTransaction, addSystemMessage } from '../../api';
	
	export default {
		
		components: {
			Alert,
			FormField,
			LayoutDiv,
			Scroller,
			Spinner,
			SubContentBtn
		},
		
		mixins: [
			AlertResize
		],
		
		props: {
			
			chatInfo: {
				type: Object,
				required: true
			},
			
			userIsInChannel: {
				type: Boolean,
				default: false
			}
			
		},
		
		data: () => {
			
			let provinces = (() => {
				
				return [
					{
						name: 'Alberta',
						abbreviation: 'AB'
					},
					{
						name: 'British Columbia',
						abbreviation: 'BC'
					},
					{
						name: 'Manitoba',
						abbreviation: 'MB'
					},
					{
						name: 'New Brunswick',
						abbreviation: 'NB'
					},
					{
						name: 'Newfoundland and Labrador',
						abbreviation: 'NL'
					},
					{
						name: 'Northwest Territories',
						abbreviation: 'NT'
					},
					{
						name: 'Nova Scotia',
						abbreviation: 'NS'
					},
					{
						name: 'Nunavut',
						abbreviation: 'NU'
					},
					{
						name: 'Ontario',
						abbreviation: 'ON'
					},
					{
						name: 'Prince Edward Island',
						abbreviation: 'PE'
					},
					{
						name: 'Québec',
						abbreviation: 'QC'
					},
					{
						name: 'Saskatchewan',
						abbreviation: 'SK'
					},
					{
						name: 'Yukon Territory',
						abbreviation: 'YT'
					}
				].reduce((obj, province) => {
					
					obj[province.abbreviation] = province.name;
					
					return obj;
					
				}, {});
				
			}).call(this);
			
			let years = (() => {
				
				let obj = {};
				let year = (new Date()).getFullYear();
				
				do {
					
					let yy = ('' + year).slice(-2);
					
					obj[yy] = year;
					
					year++;
					
				} while (Object.keys(obj).length < 10);
				
				return obj;
				
			}).call(this);
			
			let months = (() => {
				
				return [
					"January",
					"February",
					"March",
					"April",
					"May",
					"June",
					"July",
					"August",
					"September",
					"October",
					"November",
					"December"
				].reduce((obj, month, index) => {
					
					let num = index + 1;
					let numString = (num > 9) ? '' + num : '0' + num;
					
					obj[num] = `${numString} - ${month}`;
					return obj;
					
				}, {});
				
			}).call(this);
			
			return {
				sendingForm: false,
				errorMessage: null,
				options: {
					provinces,
					months,
					years
				}
			};
			
		},
		
		computed: {
			
			scrollInnerClasses() {
				
				return {
					'pllenty-chat-with-alert': this.errorMessage || this.chatInfo.formMessages.length,
					'pllenty-chat-not-looking-disabled': this.chatInfo.isHubAgent,
					'pllenty-chat-form-is-shown': this.chatInfo.isHubAgent && this.chatInfo.formShown,
					'pllenty-chat-form-not-shown': this.chatInfo.isHubAgent && !this.chatInfo.formShown
				};
				
			},
			
			formFields() {
				
				if (!this.chatInfo.config || !this.chatInfo.config.formFields) {

					return;
				
				}
				
				let formFields = Object.assign({}, this.chatInfo.config.formFields);
				
				Object.keys(formFields).map(i => {
					
					if (typeof formFields[i].options === 'string') {
						
						let options = this.options[formFields[i].options];
						
						if (options) {
							
							formFields[i].options = options;
							
							if (this.chatInfo.isHubAgent) {
								
								switch (i) {
									
									case 'card_month':
										formFields[i].options = {
											'*': '**'
										};
										break;
									
									case 'card_year':
										formFields[i].options = {
											'**': '****'
										};
										break;
									
								}
								
							}
							
						}
					
					}
					
				});
				
				
				return formFields;
				
			}
			
		},
		
		watch: {
			
			'chatInfo.config.testMode': {
				handler(val) {
					
					if (
						(val === true || val === 'Form')
						&& (!this.chatInfo.formData || !this.chatInfo.formData.card_num)
					) {

						this.$set(this.chatInfo, 'formData', Object.assign(this.chatInfo.formData, {
							first_name: 'John',
							last_name: 'Doe',
							address_1: '123 Test Street',
							address_2: 'Suite 345',
							city: 'City Town',
							region: "YT",
							postal_code: 'G1Q 1Q9',
							amount: '1.00',
							card_num: '4111111111111111',
							card_name: 'John Doe',
							card_month: '8',
							card_year: '22',
							card_cvv: '123'
						}));
						
					}
					
				},
				immediate: true
			}
			
		},
		
		methods: {
			
			saveData() {
				
				let _self = this;
				
				this.sendingForm = true;
				
				postTransaction(this.chatInfo, this.chatInfo.formData).then(response => {
					
					if (response.data && response.data.errors) {
						
						// console.error('[formPane][saveData] returned errors', '\n\n', JSON.parse(JSON.stringify(response.data)));
						
					} else {
						
						// console.log('[formPane][saveData] postTransaction result', response, postTransaction);
						
						let newFormData = Object.assign({}, this.chatInfo.formData);
						
						Object.keys(newFormData).forEach(key => {
							
							if (['amount', 'card_num', 'card_month', 'card_year', 'card_cvv', 'card_name'].includes(key)) {
								
								delete newFormData[key];
								
							}
							
						});
						
						this.$set(this.chatInfo, 'formShown', false);
						this.$set(this.chatInfo, 'formData', newFormData);
						
						this.$emit('form-finished', response);
						
					}
					
				}).catch(error => {
					
					// console.error('[formPane][saveData] caught error', '\n\n', JSON.parse(JSON.stringify(error)));
					
					if (error.response && error.response.data && error.response.data.errorMessage) {
						
						_self.errorMessage = error.response.data.errorMessage;
						
					} else if (error.errorMessage) {
						
						_self.errorMessage = error.errorMessage;
						
					} else {
						
						_self.errorMessage = error.toString();
						
					}
					
					addSystemMessage(this.chatInfo.session, {
						'type': 'formFailure'
					});
					
				}).finally(() => {
					
					this.sendingForm = false;
					
				});
				
			}
			
		}
		
	};

</script>

<style scoped lang="scss">
	
	/deep/ .#{$namespace}-alert {
		margin-bottom: 0 !important;
		border-top-left-radius: $border-radius;
		border-top-right-radius: $border-radius;
	}
	
	.#{$namespace}-content {
		position: relative;
		background: transparent;
		border: none;
	}
	
	.#{$namespace}-content-scroll {
		&:before {
			content: " ";
			display: block;
			width: 100%;
			height: 20px;
			background: linear-gradient(to bottom, $bg 0%, rgba(251, 251, 251, 0) 100%);
			position: absolute;
			top: 0;
			z-index: 5;
			padding-right: 10px;
			background-clip: content-box;
		}
		
		&:after {
			content: " ";
			display: block;
			width: 100%;
			height: 30px;
			background: linear-gradient(to bottom, rgba(251, 251, 251, 0) 0%, $bg 100%);
			position: absolute;
			bottom: -1px;
			z-index: 5;
			padding-right: 10px;
			background-clip: content-box;
		}
	}
	
	.#{$namespace}-content-scroll-inner {
		padding: 0;
		padding-top: 5px;
		padding-right: 12px !important;
		padding-bottom: 30px;
		
		&.#{$namespace}-with-alert {
			transition: padding-bottom 500ms linear;
			
			&:after {
				transition: bottom 500ms linear;
				bottom: 35px;
			}
		}
		
		&.#{$namespace}-not-looking-disabled {
			
			/deep/ .#{$namespace}-form-custom-html.#{$namespace}-disabled {
				opacity: 1 !important;
			}
			
			/deep/ .#{$namespace}-form-field {
				
				input[type="text"],
				input[type="email"],
				input[type="url"],
				input[type="password"],
				input[type="search"],
				input[type="number"],
				input[type="tel"],
				input[type="range"],
				input[type="date"],
				input[type="month"],
				input[type="week"],
				input[type="time"],
				input[type="datetime"],
				input[type="datetime-local"],
				input[type="color"],
				select,
				textarea {
					
					&[disabled], &.#{$namespace}-disabled {
						opacity: 1 !important;
					}
					
				}
				
				/deep/ .v-select.vs--single.vs--disabled {
					
					.vs__dropdown-toggle {
						opacity: 1 !important;
					}
					
					input {
						background: transparent !important;
					}
					
				}
				
			}
			
		}
		
		&.pllenty-chat-form-is-shown, &.pllenty-chat-form-not-shown {
			padding-left: 2px;
		}
		
		&.pllenty-chat-form-is-shown {
			
			/deep/ .#{$namespace}-form-field {
				input[type="text"],
				input[type="email"],
				input[type="url"],
				input[type="password"],
				input[type="search"],
				input[type="number"],
				input[type="tel"],
				input[type="range"],
				input[type="date"],
				input[type="month"],
				input[type="week"],
				input[type="time"],
				input[type="datetime"],
				input[type="datetime-local"],
				input[type="color"],
				select,
				textarea {
					box-shadow: 0 0 5px $highlighting;
				}
				
				/deep/ .v-select.vs--single {
					border-radius: $input-border-radius;
					box-shadow: 0 0 5px $highlighting;
					
					input {
						box-shadow: none;
					}
				}
				
			}
			
		}
		
	}
	
	.#{$namespace}-form-field {
		margin-top: 7px;
	}
	
	/deep/ .#{$namespace}-vb-dragger {
		right: -5px !important;
	}

</style>
