<template>
	
	<transition
		ref="self"
		name="height"
		appear
		@enter="enter"
		@after-enter="afterEnter"
		@leave="leave"
		@after-leave="afterLeave"
	>
		
		<slot />
		
	</transition>

</template>

<script>
	
	export default {
		
		methods: {
			
			enter(element) {
				
				element.style.width = getComputedStyle(element).width;
				element.style.position = 'absolute';
				element.style.visibility = 'hidden';
				element.style.height = 'auto';
				
				const height = getComputedStyle(element).height;
				
				element.style.width = null;
				element.style.position = null;
				element.style.visibility = null;
				element.style.height = 0;
				
				// Force repaint to make sure the
				// animation is triggered correctly.
				getComputedStyle(element).height;
				
				// Trigger the animation.
				// We use `setTimeout` because we need
				// to make sure the browser has finished
				// painting after setting the `height`
				// to `0` in the line above.
				setTimeout(() => {
					
					element.style.height = height;
					
					this.$emit('enter', this.$refs.self);
					
				});
				
			},
			
			afterEnter(element) {
				
				element.style.height = 'auto';
				
				this.$emit('after-enter', this.$refs.self);
				
			},
			
			leave(element) {
				
				element.style.height = getComputedStyle(element).height;
				
				// Force repaint to make sure the
				// animation is triggered correctly.
				getComputedStyle(element).height;
				
				setTimeout(() => {
					
					element.style.height = 0;
					
					this.$emit('leave', this.$refs.self);
					
				});
				
			},
			
			afterLeave() {
				
				this.$emit('after-leave', this.$refs.self);
				
			}
			
		}
		
	};

</script>

<style scoped lang="scss">
	
	* {
		will-change: height;
		transform: translateZ(0);
		backface-visibility: hidden;
		perspective: 1000px;
	}
	
	.height-enter-active,
	.height-leave-active {
		transition-property: opacity, height;
		transition-duration: 500ms;
		transition-timing-function: ease-in-out;
		overflow: hidden;
	}
	
	.height-enter,
	.height-leave-to {
		height: 0;
		opacity: 0;
	}
	
</style>
