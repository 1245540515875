<template>
	
	<div class="pllenty-chat-content-scroll-inner">
		
		<p class="pllenty-chat-connexion pllenty-chat-ellipsis">
			Please wait while you are being connected to a support agent
		</p>
		
		<auto-response v-for="(message, i) in chatInfo.autoResponses" :key="i" :message="message" />
		
	</div>
	
</template>

<script>
	
	import { AutoResponse } from '@/app/elements/components';
	
	export default {
		
		components: {
			AutoResponse
		},
		
		props: {
			
			chatInfo: {
				type: Object,
				required: true
			}
			
		}
		
	};
	
</script>

<style scoped lang="scss">
	
	.#{$namespace}-message-system {
		padding: 0;
		border: 0;
		margin-bottom: 1em;
		
		/deep/ .#{$namespace}-message-content {
			color: $autoResponses;
		}
		
		/deep/ .#{$namespace}-message-time {
			display: none;
		}
		
	}
	
	@keyframes #{$namespace}-ellipsis-animation {
		to {
			width: 1em;
		}
	}
	
	.#{$namespace}-ellipsis {
		&:after {
			content: "\2026";
			overflow: hidden;
			display: inline-block;
			width: 0;
			height: 1em;
			vertical-align: baseline;
			font-size: 1.1em;
			color: inherit;
			animation: #{$namespace}-ellipsis-animation steps(4, end) 900ms infinite;
			position: relative;
			top: 0.03em;
			left: 0.03em;
		}
	}

</style>
