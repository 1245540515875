import Vue from 'vue';
import VueCustomElement from 'vue-custom-element';
import 'document-register-element/build/document-register-element';
import VueMoment from 'vue-moment';
import Vuebar from 'vuebar';

import { App } from './app';

// https://www.npmjs.com/package/sanitize-html#what-are-the-default-options
import sanitizeOptions from './app/elements/sanitizeHtml';
Object.defineProperty(Vue.prototype, '$sanitizeOptions', {value: sanitizeOptions});

import SafeCookies from './app/elements/SafeCookies';
Object.defineProperty(Vue.prototype, '$safeCookies', {value: SafeCookies});

// Configure Vue to ignore the element name when defined outside of Vue.
Vue.config.ignoredElements = ['pllenty-chat'];

// Enable the plugins
Vue.use(VueCustomElement);
Vue.use(VueMoment);
Vue.use(Vuebar);

Vue.config.productionTip = false;

// Register your component
Vue.customElement('pllenty-chat', App, {
	// Additional Options: https://github.com/karol-f/vue-custom-element#options
	constructorCallback() {
		
		// console.info('[Vue.customElement : constructorCallback]', '\n', this, '\n', process.env);
		
	}
});
