<template>
	
	<div class="pllenty-chat-content-scroll-inner">

		<form-field v-model="value" :field="{label: 'Email', required: true, autocomplete: 'email'}" name="email" />
		<form-field v-model="value" :field="{label: 'First Name', autocomplete: 'given-name'}" name="firstName" />
		<form-field v-model="value" :field="{label: 'Last Name', autocomplete: 'family-name'}" name="lastName" />
		<form-field v-model="value" :field="{label: 'Phone', autocomplete: 'tel'}" name="phone" />

	</div>
	
</template>

<script>
	
	import { FormField } from '../../elements/components';
	
	export default {
		
		components: {
			FormField
		},
		
		props: {
			
			chatInfo: {
				type: Object,
				required: true
			},
			
			value: {
				type: Object,
				default: () => {}
			}
			
		}
		
	};

</script>

<style scoped lang="scss">

</style>
