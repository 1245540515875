<template>
	
	<div>
		
		<template v-if="chatInfo.isHubAgent || typeof title !== 'string'">
			
			<img
				:src="assetsUrl + '/chat-badge.png'"
				class="pllenty-chat-badge"
			/>
			
			<img
				:src="assetsUrl + '/headline-logo.png'"
				class="pllenty-chat-headline-logo"
			/>
			
		</template>
		
		<template v-else>
			
			<img :src="badge" class="pllenty-chat-badge" />
			
			<h2>{{ title }}</h2>
			
		</template>
		
		
		
	</div>

</template>

<script>
	
	export default {
		
		props: {
			
			chatInfo: {
				type: Object,
				required: true
			}
			
		},
		
		data() {
			
			return {
				assetsUrl: process.env.VUE_APP_ASSETS,
				appBaseUrl: process.env.VUE_APP_BASE
			};
			
		},
		
		computed: {
			
			title() {
				
				return this.chatInfo.config.title;
				
			},
			
			badge() {
				
				let badge = (this.chatInfo.config.badge) ? this.chatInfo.config.badge : this.assetsUrl + '/chat-badge.png';
				
				if (badge === true) {
					
					badge = this.appBaseUrl + this.chatInfo.companyId + '/assets/chat-badge.png';
					
				}
				
				return badge;
				
			}
			
		}
		
	};

</script>

<style scoped lang="scss">
	
	.#{$namespace}-headline-logo {
		height: $header-height !important;
		width: auto;
		padding: $logo-padding;
	}
	
	.#{$namespace}-badge {
		position: absolute;
		top: $badge-top-offset;
		left: $badge-left-offset;
		height: $badge-height !important;
		width: auto;
		
		& + * {
			margin-left: $badge-width !important;
		}
		
	}
	
	.#{$namespace}-message {
		padding: 10px 16px 10px 10px;
		border-bottom: $list-border;
	}

</style>
