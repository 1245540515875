<template>
	
	<form name="pllenty-chat-closing-form" class="pllenty-chat-form">
		
		<layout-div name="content">
			
			<scroller>
				
				<template v-if="!resetting">
					
					<layout-div name="content-scroll-inner">
						
						<p v-if="closed">
							This chat session has ended.
						</p>
						
						<div v-if="closed && !chatInfo.closedBy && hasAutoResponses">
						
							<auto-response :message="lastAutoResponse" />
						
						</div>
						
						<div v-else-if="!closed" class="pllenty-chat-closing-q1">
							
							<p>Are you sure you want to quit this chat session?</p>
							
							<div class="pllenty-chat-row">
								<div class="pllenty-chat-half">
									
									<button
										class="pllenty-chat-btn pllenty-chat-btn-left"
										@click.prevent="(!transcriptPossible) ? closeThis(false) : transcriptDisabled = false"
									>
										<spinner v-if="!transcriptPossible && sendingCloseRequest" size="1" />
										<template v-else>
											Yes, Goodbye
										</template>
									</button>
								
								</div>
								<div class="pllenty-chat-half">
									
									<button
										class="pllenty-chat-btn pllenty-chat-btn-right"
										@click.prevent="goBack"
									>
										No, I'm staying
									</button>
								
								</div>
							</div>
						
						</div>
					
						<div v-if="transcriptPossible" class="pllenty-chat-closing-q2">
							
							<p>Would you like a transcript of the chat sent to you by email?</p>
							
							<div class="pllenty-chat-row">
								<div class="pllenty-chat-half">
									
									<button
										class="pllenty-chat-btn pllenty-chat-btn-left"
										:disabled="transcriptDisabled"
										@click.prevent="closeThis(true)"
									>
										<template v-if="!sendingCloseRequestWithTranscript">
											Sure, why not?
										</template>
										<spinner v-else size="1" />
									</button>
									
								</div>
								<div class="pllenty-chat-half">
									
									<button
										class="pllenty-chat-btn pllenty-chat-btn-right"
										:disabled="transcriptDisabled"
										@click.prevent="closeThis(false)"
									>
										<template v-if="!sendingCloseRequest">
											No, I'm good
										</template>
										<spinner v-else size="1" />
									</button>
									
								</div>
							</div>
							
						</div>
						
					</layout-div>
					
				</template>
				
				<layout-div v-else name="content-scroll-inner">
					
					<h3 style="margin-bottom: 1em;">
						Thank You!
					</h3>
					
					<p>
						This chat will be reset in <countdown @hook:mounted="$emit('resetting')" @whenCountIsZero="$emit('reset')">
							5
						</countdown>
					</p>
					
				</layout-div>
			
			</scroller>
			
		</layout-div>
		
		<sub-content-btn btn="start" :disabled="true">
			&mdash;
		</sub-content-btn>
		
	</form>
	
</template>

<script>
	
	import { AutoResponse, LayoutDiv, Scroller, Spinner, SubContentBtn } from '../../elements/components';
	import { closeChat } from '../../api';
	import Countdown from './Countdown';
	
	export default {
		
		components: {
			AutoResponse,
			Countdown,
			LayoutDiv,
			Scroller,
			Spinner,
			SubContentBtn
		},
		
		props: {
			
			chatInfo: {
				type: Object,
				required: true
			}
			
		},
	
		data: () => {
	
			return {
				transcriptDisabled: true,
				resetting: false,
				sendingCloseRequest: false,
				sendingCloseRequestWithTranscript: false,
			};
		
		},
		
		computed: {
			
			closed() {
				
				return (this.chatInfo && this.chatInfo.status === 'Closed');
				
			},
			
			hasAutoResponses() {
				
				return Object.keys(this.chatInfo.autoResponses).length;
				
			},
			
			lastAutoResponse() {
				
				return Object.values(this.chatInfo.autoResponses).pop();
				
			},
			
			transcriptPossible() {
				
				let keys = Object.keys(this.chatInfo.messages);
				
				for (let i = 0; i < keys.length; i++) {
					
					let key = keys[i];
					let msg = this.chatInfo.messages[key];
					
					if (msg.type !== 'System') {
						
						return true;
						
					}
					
				}
				
				return false;
				
			}
			
		},
		
		created() {
			
			// change the default if the chat has already been closed
			// only visitors will ever get here, agents don't use the closing view
			
			if (this.closed && !this.hasAutoResponses && !this.transcriptPossible) {
				
				this.resetting = true;
				
			} else if (this.closed) {
				
				this.transcriptDisabled = false;
				
			}
			
		},
	
		methods: {
			
			goBack() {
				
				this.$emit('updateDisplayPane', this.$parent.$parent.previousPane);
				
			},
			
			closeThis(sendTranscript) {
				
				this.sendingCloseRequest = !sendTranscript;
				this.sendingCloseRequestWithTranscript = sendTranscript;
				
				closeChat(this.chatInfo.session, {sendTranscript: sendTranscript})
					.then(() => {
						
						this.resetting = true;
						
					})
					.catch(error => {
						
						this.$emit('error', error);
						
					})
					.finally(() => {
						
						this.sendingCloseRequest = false;
						this.sendingCloseRequestWithTranscript = false;
						
					});
			
			}
			
		}
		
	};

</script>

<style scoped lang="scss">
	
	.#{$namespace}-message-system {
		padding: 0;
		border: 0;
		margin-bottom: 1em;
		
		/deep/ .#{$namespace}-message-content {
			color: $autoResponses;
		}
		
		/deep/ .#{$namespace}-message-time {
			display: none;
		}
		
	}
	
	.#{$namespace}-closing-q1 {
		margin-bottom: 15px;
	}
	
	.#{$namespace}-btn-left, .#{$namespace}-btn-right {
		width: 95%;
		font-size: .97em;
	}
	
	.#{$namespace}-btn-left {
		margin-right: 5px;
	}
	
	.#{$namespace}-btn-right {
		margin-left: 5px;
	}

</style>
