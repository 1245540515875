<template>
	
	<div
		v-flashing="boxFlashing"
		:class="classObject"
		@pllenty-chat-launch="launch"
		@stop-flashing="boxFlashing = false"
		@click="onChatClick"
	>
		
		<layout-div name="header">
			
			<heading-branding :chat-info="chatInfo" />
			
			<div class="pllenty-chat-header-actions">
				
				<template v-if="enableUserInvitation">
					
					<a
						v-if="!invite"
						href="javascript:;"
						class="pllenty-chat-btn"
						@click.prevent="inviteUser"
					>
						<icon name="user-add" />
						Invite
					</a>
					
					<a
						v-else
						href="javascript:;"
						class="pllenty-chat-btn"
						@click.prevent="invite = false"
					>
						<icon name="credit-card" />
						Form
					</a>
				
				</template>
				
				<template v-if="enableForm">
					
					<a
						v-if="chatForm"
						href="javascript:;"
						class="pllenty-chat-btn"
						:class="{'pllenty-chat-disabled': !boxActive}"
						@click.prevent="chatInfo.formShown = false"
					>
						<i class="pllenty-chat-bubbles" aria-label="false"></i>
						Chat
					</a>
					
					<a
						v-else
						href="javascript:;"
						class="pllenty-chat-btn"
						:class="{'pllenty-chat-disabled': !boxActive}"
						@click.prevent="chatInfo.formShown = true"
					>
						<icon name="credit-card" />
						Pay
					</a>
					
				</template>
				
				<icon
					v-if="enableCollapse"
					:name="toggler.icon"
					:label="toggler.label"
					@click.native="toggleCollapse"
				/>
				
				<spinner v-if="showClose && showCloseSpinner" class="pllenty-chat-close-spinner" :size="1"></spinner>
				
				<icon
					v-else-if="showClose"
					name="cross"
					label="Close"
					:class="{'pllenty-chat-disabled': disableClose}"
					@click.native="close"
				/>
				
			</div>
		
		</layout-div>
		
		<layout-div name="top-alerts">
			
			<alert
				v-model="errorMessage"
				type="error"
				@enter="startAlertResize"
				@after-enter="stopAlertResize"
				@leave="startAlertResize"
				@after-leave="stopAlertResize"
			/>
			
			<alert
				v-model="infoMessage"
				type="info"
				@enter="startAlertResize"
				@after-enter="stopAlertResize"
				@leave="startAlertResize"
				@after-leave="stopAlertResize"
			/>
			
			<div v-show="showFormPane" style="overflow: hidden">
				<alert
					v-for="(message, msgId) in filteredFormMessages"
					:key="msgId"
					v-model="chatInfo.formMessages[msgId].message"
					type="info"
					:auto-close="false"
					@enter="startAlertResize"
					@after-enter="stopAlertResize"
					@leave="startAlertResize"
					@after-leave="stopAlertResize"
				/>
			</div>
		
		</layout-div>
		
		<layout-div name="form-wrapper">
			
			<div style="overflow: hidden;">
				
				<request-pane
					v-if="displayPane === 'Request'"
					v-model="chatInfo.session"
					:chat-info="chatInfo"
				/>
				
				<chat-pane
					v-if="displayPane === 'Chat'"
					:chat-info="chatInfo"
					:enabled="enableChat"
					:user-is-in-channel="userIsInChannel"
					@addMessage="addMessage"
					@saveUserId="saveSession"
				/>
				
				<form-pane
					v-if="showFormPane"
					:chat-info="chatInfo"
					:user-is-in-channel="userIsInChannel"
					@form-finished="whenFormFinished"
				/>
				
				<invitation-pane
					v-if="showUserInvitationPane"
					:chat-info="chatInfo"
					:users="users"
				/>
				
				<close-pane
					v-if="displayPane === 'Closing'"
					:chat-info="chatInfo"
					@updateDisplayPane="displayPane = $event"
					@resetting="resetting = true"
					@reset="reset()"
					@error="reset()"
				/>
				
			</div>
			
		</layout-div>
	
	</div>

</template>

<script>
	
	import { getConfig, getInfo, userQuit } from './api';
	import { Alert, LayoutDiv, HeadingBranding, Icon } from './elements/components';
	import { Flashing } from "./elements/directives";
	import { RequestPane } from './request/components';
	import { ClosePane } from './closing/components';
	import { ChatPane } from './chat/components';
	import { FormPane } from './forms/components';
	import InvitationPane from './invitation/components/InvitationPane';
	import Spinner from "./elements/components/Spinner";
	import AlertResize from './elements/mixins/AlertResize';
	
	function toggler(key) {
		
		let data = {
			up: {
				icon: 'chevron-up',
				label: 'Expand'
			},
			down: {
				icon: 'chevron-down',
				label: 'Collapse'
			}
		};
		
		return data[key];
		
	}
	
	function dataDefaults(chatInfo = null) {
		
		let defaultData = {
			chatInfo: {
				config: {},
				messages: {},
				autoResponses: {},
				users: {},
				status: null,
				session: null,
				formShown: false,
				formMessages: {},
				formData: {}
			},
			forms: {
				values: {},
				validation: {}
			},
			errorMessage: null,
			infoMessage: null,
			firstRefresh: false,
			boxActive: true,
			boxShowing: false,
			boxFlashing: false,
			displayPane: 'Request',
			previousPane: null,
			soundTimer: null,
			chatRefreshTimer: null,
			resetting: false,
			showCloseSpinner: false,
			invite: false
		};
		
		if (chatInfo) {
			
			defaultData.chatInfo = Object.assign(defaultData.chatInfo, chatInfo);
			
		}
		
		return defaultData;
		
	}
	
	export default {
		
		directives: {
			Flashing
		},
		
		components: {
			Spinner,
			Alert,
			LayoutDiv,
			HeadingBranding,
			Icon,
			RequestPane,
			ChatPane,
			FormPane,
			ClosePane,
			InvitationPane
		},
		
		mixins: [
			AlertResize
		],
		
		props: {
			
			id: {
				type: String,
				validator: val => /[a-z\d\-]+/.test(val),
				default: ''
			},
			
			show: {
				type: Boolean,
				default: false
			},
			
			session: {
				type: Object,
				default() {
					
					return null;
					
				},
				validator: val => {
					
					return (
						val.id
						&& val.hub
						&& val.hub.id
						&& val.hub.email
						&& val.hub.firstName
						&& val.hub.lastName
					);
					
				}
			},
			
			users: {
				type: Array,
				default() {
					
					return [];
					
				}
			}
		
		},
		
		data() {
			
			// this.chatInfo.status
			// 'Requested'
			// 'Chatting'
			// 'Closed'
			
			// this.displayPane
			// 'Request'
			// 'Chat'
			// 'Form'
			// 'Closing' -> multiple states
			
			return Object.assign({}, dataDefaults(), {
				assetsUrl: process.env.VUE_APP_ASSETS,
				appBaseUrl: process.env.VUE_APP_BASE,
				displayPane: null,
				boxActive: false,
				toggler: toggler('down'),
				soundBank: (() => {
					
					let types = ['newMessage', 'myNewMessage', 'connected', 'unfocusedAlert'];
					let sounds = {};
					
					types.forEach((val) => {
						
						sounds[val] = new Audio(process.env.VUE_APP_ASSETS + '/' + val + '.wav');
						
					});
					
					return sounds;
					
				}).call(this)
			});
			
		},
		
		computed: {
			
			hubAgentCanQuit() {
				
				if (!this.chatInfo || !this.chatInfo.isHubAgent || !this.chatInfo.users) {

					return false;
				
				}
				
				let _self = this;
				
				let isSomeoneElseInChat = Object.values(this.chatInfo.users).filter(user => {
					
					return user.type === 'Hub' && user.email !== _self.chatInfo.session.hub.email;
					
				});
				
				return Boolean(isSomeoneElseInChat.length);
				
			},
			
			hasAutoResponses() {
				
				return Object.keys(this.chatInfo.autoResponses).length;
				
			},
			
			hasAlert() {
				
				return (
					(this.formMessages && Object.keys(this.formMessages).length)
					|| (this.errorMessage && this.errorMessage.length)
					|| (this.infoMessage && this.infoMessage.length)
				);
				
			},
			
			filteredFormMessages() {
				
				if (!this.formMessages || !Object.keys(this.formMessages).length) {
					return {};
				}
				
				let obj = {};
				
				Object.keys(this.formMessages).forEach(key => {
					
					let msg = this.formMessages[key];
					
					if (msg.type !== 'System') {
						
						obj[key] = msg;
						
					}
					
				});
				
				return obj;
				
			},
			
			formMessages() {
				
				if (
					!this.chatInfo
					|| this.chatInfo.isHubAgent
					|| !this.chatInfo.formMessages
				) {
					
					return {};
					
				}
				
				return (typeof this.chatInfo.formMessages === 'object') ? this.chatInfo.formMessages : {};
				
			},
			
			disableClose() {
				
				return (
					(this.chatInfo.isHubAgent && !this.hubAgentCanQuit)
					|| (this.alwaysShow && !this.chatInfo.session)
					|| (this.showCloseSpinner && !this.resetting)
				);
				
			},
			
			showClose() {
				
				return (
					(
						this.chatInfo.status !== 'Closed' || (
						this.chatInfo.status === 'Closed'
					&& (this.showCloseSpinner || this.resetting || this.hasAutoResponses)
					)
					)
					&& (!this.chatInfo.isHubAgent || this.userIsInChannel)
				);
				
			},
			
			enableCollapse() {
				
				return (!this.chatInfo.isHubAgent);
				
			},
			
			enableForm() {
				
				return (
					this.chatInfo.status
					&& this.chatInfo.status !== 'Requested'
					&& this.chatInfo.status !== 'Closed'
					&& !this.chatInfo.isHubAgent
				);
				
			},
			
			enableChat() {
				
				return (
					this.chatInfo.status !== 'Closed'
					&& (
						this.chatInfo.isHubAgent
					|| this.chatInfo.status !== 'Requested'
					)
				);
				
			},
			
			enableUserInvitation() {
				
				return (
					this.enableChat
					&& this.chatInfo.isHubAgent
					&& this.userIsInChannel
					&& this.users.length
				);
				
			},
			
			showFormPane() {
				
				return (
					this.displayPane === 'Form'
					|| (
						this.chatInfo.isHubAgent && !this.showUserInvitationPane
					&& (this.displayPane === 'Chat' || this.displayPane === 'Form')
					)
				);
				
			},
			
			showUserInvitationPane() {
				
				return (this.chatInfo.isHubAgent && this.invite);
				
			},
			
			alwaysShow() {
				
				return (this.chatInfo.isHubAgent || (
					this.show != false
					&& this.show !== undefined
					&& this.show !== "false"
					&& this.show !== "0"
				));
			
			},
			
			classObject() {
				
				return {
					'pllenty-chat': true,
					'pllenty-chat-static': this.chatInfo.isHubAgent,
					'pllenty-chat-ready': this.boxShowing,
					'pllenty-chat-hidden': !this.boxShowing,
					'pllenty-chat-active': this.boxActive,
					'pllenty-chat-inactive': !this.boxActive
				};
				
			},
			
			chatSession() {
				
				return this.chatInfo.session;
				
			},
			
			chatForm() {
				
				return this.enableForm && this.chatInfo.formShown;
				
			},
			
			chatUsers() {
				
				if (!this.chatInfo || !this.chatInfo.users) {
					
					return {};
					
				}
				
				return this.chatInfo.users;
				
			},
			
			chatStatus() {
				
				return this.chatInfo.status;
				
			},
			
			userIsInChannel() {
				
				if (!this.chatUsers) {

					return;
				
				}
				
				let user = Object.keys(this.chatUsers).find(i => {
					
					return Boolean(
						(this.chatUsers[i].active === true || this.chatUsers[i].active === 'true')
							&& (
								this.chatUsers[i].type === 'Me'
							|| Boolean(this.chatInfo.session.hub && this.chatInfo.session.hub.email === this.chatUsers[i].email)
							)
					);
					
				});
				
				return Boolean(user);
				
			},
			
			companyId() {
				
				
				if (typeof this.id === 'string' && /[a-z\d\-]+/.test(this.id)) {
					
					return this.id;
					
				}
				
				let checkScript = (scriptUrl) => {
					
					let regex = /^https:\/\/chat\.pllenty\.com\/([a-z\d-]+?)\/chat\.js$/;
					let match = scriptUrl.match(regex);
					
					if (Array.isArray(match) && match[1]) {
						
						return match[1];
						
					} else if (process.env.VUE_APP_DEBUG) {
						
						let regex2 = /^https:\/\/s3\.(?:[a-z\d\-]+?\.)?amazonaws\.com\/pllenty-chat\/([a-z\d-]+?)\/chat\.js$/;
						let match2 = scriptUrl.match(regex2);
						
						if (Array.isArray(match2) && match2[1]) {
							
							return match2[1];
							
						}
						
						let regex3 = /^https:\/\/pllenty-chat\.s3\.(?:[a-z\d\-]+?\.)?amazonaws\.com\/([a-z\d-]+?)\/chat\.js$/;
						let match3 = scriptUrl.match(regex3);
						
						if (Array.isArray(match3) && match3[1]) {
							
							return match3[1];
							
						}
						
					}
					
					return false;
					
				};
				
				let scripts = document.getElementsByTagName("script");
				
				for (let i = 0; i < scripts.length; i++) {
					
					if (scripts[i].src) {
						
						let id = checkScript(scripts[i].src);
						
						if (id) {
							
							return id;
							
						}
						
					}
					
				}
				
				throw new Error('ID is required to use Pllenty Chat Pay');
				
			}
			
		},
		
		watch: {
			
			formMessages() {
				
				let obj = {_uid: Math.random()};
				
				this.startAlertResize(obj);
				
				let self = this;
				
				setTimeout(function () {
					
					self.stopAlertResize(obj);
					
				}, 1500);
				
			},
			
			boxActive(isExpanded) {
				
				if (!isExpanded) {
					
					setTimeout(() => {
						
						this.toggler = toggler('up');
						
					}, 500);
					
				} else {
					
					this.toggler = toggler('down');
					
				}
				
			},
			
			displayPane(val, oldVal) {
				
				if (val !== oldVal) {
					
					this.previousPane = oldVal;
					
				}
				
				if (!oldVal) {

					this.previousPane = val;
				
				}
				
			},
			
			chatSession(val) {
				
				if (val) {
					
					this.chatRefresh();
					
				} else {
					
					this.displayPane = 'Request';
					
				}
				
			},
			
			chatStatus() {
				
				this.$emit('statusChange');
				this.$el.dispatchEvent(new CustomEvent('pllenty-chat-status-change'));
				
				this.updatePane();
				
			},
			
			chatForm() {
				
				this.updatePane();
				
			},
			
			chatUsers(val) {
				
				if (!val) {

					return;
				
				}
				
				let users = Object.keys(val).filter(i => {
					
					return (
						(val[i].active === true || val[i].active === 'true')
						&& val[i].type !== 'Me'
					);
					
				});
				
				if (this.chatInfo.status !== 'Closed' && users.length < 1) {
					
					this.chatInfo.status = 'Requested';
					this.updatePane();
					
				}
				
			}
			
		},
		
		created() {
			
			/*setInterval(() => {
				
				this.infoMessage = this.infoMessage ? null : 'TESTING';
				
			}, 2000);*/
			
			this.chatInfo.companyId = this.companyId;
			
			getConfig(this.chatInfo.companyId).then(data => {
				
				this.chatInfo.config = data.data;
				
			});
			
			/*
			this.chatInfo.session = {
				id: '57216e59-a2df-4c5a-9ca0-e03c2c039c9e',
				userId: '0502d6f1-016b-4256-9f10-d406c8c1a5df'
			};
			
			this.chatInfo.hubChatSession = {
				id: '57216e59-a2df-4c5a-9ca0-e03c2c039c9e',
				hub: {
					id: 107,
					email: 'todd@cbvcollections.com',
					firstName: 'Todd',
					lastName: 'Wilson'
				}
			};
			
			this.$safeCookies.set('chatSession', this.chatSession);
			this.$safeCookies.set('hubChatSession', this.hubChatSession);
			*/
			
			if (this.session) {
				
				this.firstRefresh = true;
				this.chatInfo.session = this.session;
				
				if (this.$safeCookies.isKey('hubChatSession')) {
					
					let session = this.$safeCookies.get('hubChatSession');
					
					if (session.id === this.chatInfo.session.id) {
						
						this.chatInfo.session = Object.assign(session, this.chatInfo.session);
					
					} else {
						
						this.$safeCookies.remove('hubChatSession');
						
					}
					
					// console.log('hubChatSession From Cookie', this.chatInfo.session);
					
				} else {
					
					// console.log('hubChatSession From Config', this.session);
					
				}
				
				this.boxShowing = true;
				
			} else if (this.$safeCookies.isKey('chatSession')) {
				
				this.firstRefresh = true;
				this.chatInfo.session = this.$safeCookies.get('chatSession');
				
				// console.log('chatSession From Cookie', this.$safeCookies.get('chatSession'));
				
				this.boxShowing = true;
				
			}
			
			if (!this.chatInfo.session) {
	
				this.displayPane = 'Request';
	
			} else {
				
				if (!this.chatInfo.session.users) {

					this.chatInfo.session.users = [];
				
				}
				
				if (!this.chatInfo.session.messages) {

					this.chatInfo.session.messages = [];
				
				}
				
			}
			
			if (this.chatInfo.session && this.chatInfo.session.hub) {
				
				this.chatInfo.isHubAgent = true;
				
			}
			
			this.boxActive = true;
			
			// if (!this.alwaysShow) box will only show from listening to event
			if (this.alwaysShow) {
				
				this.boxShowing = true;
				
			}
			
			// stop flashing when page is brought into focus
			
			window.addEventListener('focus', () => {
				
				setTimeout(() => {
					
					if (this.boxFlashing) {
						
						this.boxFlashing = 'ONCE';
						
					}
				
				}, 2000);
				
			});
			
		},
		
		methods: {
			
			onChatClick() {
				
				if (this.boxFlashing) {
					
					this.boxFlashing = false;
					
				}
				
			},
			
			saveSession() {
				
				if (this.chatInfo.isHubAgent) {
					
					this.$safeCookies.set('hubChatSession', this.chatInfo.session);
				
				} else {
					
					this.$safeCookies.set('chatSession', this.chatInfo.session);
				
				}
				
			},
			
			updatePane() {
				
				if (this.chatInfo.isHubAgent) {
					
					this.displayPane = 'Chat';
					
				} else if (this.chatInfo.status === 'Closed') {
					
					this.displayPane = 'Closing';
					
				} else if (this.chatInfo.status === 'Requested') {
					
					this.displayPane = 'Request';
					
				} else if (this.chatInfo.status === 'Chatting') {
					
					this.displayPane = (this.chatForm) ? 'Form' : 'Chat';
					
				}
				
			},
			
			chatRefresh() {
				
				let self = this;
				
				if (!this.chatSession) {
					
					return;
					
				}
				
				getInfo(this.chatInfo).then(res => {
					
					this.errorMessage = '';
					
					this.updateChatInfo(res.data);
					
				}).catch(error => {
					
					// console.error('getInfo(this.chatInfo)', error.toString(), '\n', JSON.parse(JSON.stringify(error)));
					
					switch (error.toString()) {
						
						case 'Error: Network Error':
							this.errorMessage = 'Network Error. Reconnecting...';
							break;
						
						case 'Error: Request failed with status code 400':
							
							this.errorMessage = error.response.data.errorMessage;
							
							if (!this.chatInfo.isHubAgent) {

								this.reset();
							
							}
							
							return;
						
						default:
							this.errorMessage = error.toString();
							return;
						
					}
					
					if (this.chatInfo.isHubAgent) {
						
						if (error.response && error.response.data && error.response.data.errorMessage) {
							
							this.errorMessage = error.response.data.errorMessage;
							
						} else if (error.errorMessage) {
							
							this.errorMessage = error.errorMessage;
							
						} else {
							
							this.errorMessage = error.toString();
							
						}
						
					}
					
				}).then(() => {
					
					self.firstRefresh = false;
					
					if (self.chatRefreshTimer) {
						
						clearTimeout(self.chatRefreshTimer);
						
					}
					
					self.chatRefreshTimer = setTimeout(() => {
						
						self.chatRefresh();
						
					}, 2000);
					
				});
				
			},
			
			updateChatInfo(data) {
				
				this.chatInfo.status = data.status; // Will automatically call updatePane() due to watcher
				this.chatInfo.closedBy = data.closedBy;
				this.chatInfo.someoneIsTyping = data.someoneIsTyping;
				
				if (data.matchingUserId) {

					this.chatInfo.session.userId = data.matchingUserId;
				
				}
				
				if (data.user) {

					this.chatInfo.user = data.user;
				
				}
				
				if (data.users) {
					
					this.compareUsers(data.users);
					
				}
				
				// update form only on first refresh or for hub agents
				
				if (this.firstRefresh || this.chatInfo.isHubAgent) {

					this.chatInfo.formShown = data.formShown;
				
				}
				
				if ((this.firstRefresh || this.chatInfo.isHubAgent) && data.formData) {
					
					['card_num', 'card_year', 'card_month', 'card_cvv', 'card_name'].map(key => {
						
						if (data.formData[key] !== undefined) {
							
							if (this.chatInfo.isHubAgent) {
								
								// let everythingButSpacesRegex = /[^\t\n\r ]/gim;
								//
								// /*if (typeof data.formData[key] === 'object') {
								//
								// 	// make sure the fake value I replace them with is available in dropdown
								//
								// 	if (data.formData[key].text) {
								//
								// 		let text = '' + data.formData[key].text;
								// 		let newText = (key === 'card_month') ? '**' : text.replace(everythingButSpacesRegex, '*');
								//
								// 		data.formData[key] = {
								// 			text: newText,
								// 			value: '*'
								// 		};
								//
								// 	} else {
								//
								// 		let text = '' + data.formData[key].value;
								// 		data.formData[key] = text.replace(everythingButSpacesRegex, '*');
								//
								// 	}
								//
								// } else {*/
								//
								// 	let text = '' + data.formData[key];
								// 	data.formData[key] = text.replace(everythingButSpacesRegex, '*');
								//
								// //}
								
							} else if (this.firstRefresh) {
								
								delete data.formData[key];
								
							}
							
						}
						
					});
					
					this.chatInfo.formData = data.formData;
					
				}
				
				// add messages
				
				Object.keys(data.messages).forEach(i => {
					
					this.addMessage({
						i: i,
						message: data.messages[i]
					});
					
				});
				
			},
			
			compareUsers(users) {
				
				let chatInfoUsers = {};
				
				if (this.chatInfo && this.chatInfo.users) {
					
					chatInfoUsers = this.chatInfo.users;
					
				}
				
				Object.keys(users).forEach(i => {
					
					let user = chatInfoUsers[users[i].userId];
					
					if (!user || user.active !== users[i].active || user.typing !== users[i].typing) {
						
						if (user && user.active !== users[i].active) {
							
							if (users[i].active === true || users[i].active === 'true') {
								
								this.playSound('connected');
								
								this.$emit('user-added');
								this.$el.dispatchEvent(new CustomEvent('pllenty-chat-user-added', users[i]));
								
							} else {
								
								if (user.id === this.chatInfo.user.id) {

									this.showCloseSpinner = false;
								
								}
								
							}
							
						}
						
						this.$set(this.chatInfo.users, users[i].userId, users[i]);
						
					}
					
					if (!this.chatInfo.session.users || !this.chatInfo.session.users.find(userId => userId === users[i].userId)) {
						
						if (!this.chatInfo.session.users) {

							this.chatInfo.session.users = [];
						
						}
						
						this.chatInfo.session.users.push(users[i].userId);
						this.saveSession();
						
						if (users[i].type !== 'Me') {
							
							this.playSound('connected');
							
							this.$emit('user-added');
							this.$el.dispatchEvent(new CustomEvent('pllenty-chat-user-added', users[i]));
						
						}
						
					}
					
				});
				
			},
			
			addMessage(data) {
				
				if (!this.chatInfo.session.messages) {

					this.chatInfo.session.messages = [];
				
				}
				
				let neverReceivedAlert = (this.chatInfo.session.messages.indexOf(data.i) === -1);
				
				let messagesGroupName = 'messages';
				
				if (data.message.type === 'System' && data.message.systemMessageType === 'autoResponse') {
					
					messagesGroupName = 'autoResponses';
					
				}
				
				if (data.message.type === 'System' && data.message.systemMessageType === 'formFailure' && !this.chatInfo.isHubAgent) {

					return;
				
				}
				
				if (data.message.userId === this.chatInfo.session.userId) {
					
					data.message.type = 'Me';
					
				}
				
				if (!this.chatInfo.messages[data.i]) {
					
					this.$set(this.chatInfo[messagesGroupName], data.i, data.message);
					
					if (data.message.type !== 'Me' && neverReceivedAlert) {
						
						this.chatInfo.session.messages.push(data.i);
						this.saveSession();
						
						// If the user has the form open
						if (this.chatForm && messagesGroupName !== 'autoResponses') {
							
							// Save a copy of the message to formMessages
							this.$set(this.chatInfo.formMessages, data.i, Object.assign({}, data.message));
							
						}
						
						if (!this.firstRefresh) {
							
							this.newMessageAlert(data.message);
							
						}
						
					}
					
					if (!this.chatForm) {
						
						let evt = new CustomEvent('scrollTo', {detail: 'bottom'});
						let scroller = this.$el.querySelector('.pllenty-chat-vb-content');
						
						if (scroller) {
							
							scroller.dispatchEvent(evt);
							
						}
						
						setTimeout(() => {
							
							if (!this.chatForm) {
								
								scroller = this.$el.querySelector('.pllenty-chat-vb-content');
								
								if (scroller) {
									
									scroller.dispatchEvent(evt);
									
								}
								
							}
							
						}, 300);
						
					}
					
				} else {
					
					// Only update time ago on message if it already exists
					this.$set(this.chatInfo[messagesGroupName][data.i], 'time_ago', data.message.time_ago);
					
				}
				
			},
			
			newMessageAlert(message) {
				
				if (message.type === 'Me') {
					
					this.playSound('myNewMessage');
					
				} else {
					
					this.playSound('newMessage');
					
					// make window flash
					
					let justOnce = (
						document.hasFocus()
						&& this.displayPane === 'Chat'
					);
					
					this.boxFlashing = justOnce ? 'ONCE' : true;
					
				}
				
				// scroll to bottom
				
				if (this.displayPane === 'Chat') {
					
					let evt = new CustomEvent('scrollTo', {detail: 'bottom'});
					this.$el.querySelector('.pllenty-chat-vb-content').dispatchEvent(evt);
					
					setTimeout(() => {
						
						if (this.displayPane === 'Chat') {

							let evt = new CustomEvent('scrollTo', {detail: 'bottom'});
							this.$el.querySelector('.pllenty-chat-vb-content').dispatchEvent(evt);
						
						}
						
					}, 500);
					
				}
				
			},
			
			toggleCollapse() {
				
				this.boxActive = !this.boxActive;
				
				// cancel closing state
				
				if (!this.boxActive && (this.displayPane === 'Closing' && (!this.resetting && !this.chatInfo.status === 'Closed'))) {
					
					setTimeout(() => {
						
						this.displayPane = this.previousPane;
						
					}, 1000);
					
				}
				
			},
			
			close() {
				
				if (this.chatInfo.session === null || this.resetting || this.chatStatus === 'Closed') {
					
					this.reset();
					
					return;
					
				}
				
				if (this.chatInfo.isHubAgent) {
					
					let _self = this;
					
					this.showCloseSpinner = true;
					
					userQuit(this.chatInfo).then(res => {
						
						if (res === undefined) {
							
							_self.$emit('quit', {message: 'userId not in channel'});
							this.showCloseSpinner = false;
							
						} else {
							
							_self.$emit('quit', res.data);
							
						}
						
					}).catch(error => {
						
						// console.error('getInfo(this.chatInfo)', error.toString(), '\n', JSON.parse(JSON.stringify(error)));
						
						if (error.response && error.response.data && error.response.data.errorMessage) {
							
							_self.errorMessage = error.response.data.errorMessage;
							
						} else if (error.errorMessage) {
							
							_self.errorMessage = error.errorMessage;
							
						} else {
							
							_self.errorMessage = error.toString();
							
						}
						
					});
					
					return false;
					
				}
				
				this.displayPane = (this.displayPane === 'Closing') ? this.previousPane : 'Closing';
				this.showCloseSpinner = (this.isHubAgent && this.displayPane !== 'Closing');
				
				this.boxActive = true;
				
			},
			
			launch() {
				
				this.boxShowing = true;
				this.boxActive = true;
				
			},
			
			inviteUser() {
				
				if (!this.enableUserInvitation) {
					
					return;
					
				}
				
				this.invite = true;
				
			},
			
			whenFormFinished(response) {
				
				this.infoMessage = 'Your payment has been processed. Thank you!';
				
			},
			
			reset() {
				
				let resetTimer = this.alwaysShow ? 0 : 200;
				this.boxShowing = this.alwaysShow;
				
				// if (!this.alwaysShow) box will show again from listening to event
				
				setTimeout(() => {
					
					if (this.chatInfo.isHubAgent) {
						
						this.$safeCookies.remove('hubChatSession');
						
					} else {
						
						this.$safeCookies.remove('chatSession');
						
					}
					
					let defaults = dataDefaults({
						companyId: this.chatInfo.companyId,
						config: this.chatInfo.config
					});
					
					Object.assign(this.$data, defaults);
					this.displayPane = 'Request';
					
					this.$emit('reset');
					document.dispatchEvent(new CustomEvent('pllenty-chat-reset'));
					
				}, resetTimer);
				
			},
			
			playSound(type) {
				
				let limit = parseInt(this.$safeCookies.get('soundCatcher')) + 1000;
				
				if (Date.now() < limit) {
					
					return;
					
				}
				
				// sound type = one of ['newMessage', 'myNewMessage', 'unfocusedAlert', 'connected']
				
				try {
					
					let sound = this.soundBank[type];
					
					if (
						sound.currentTime > 0
						&& !sound.paused
						&& !sound.ended
						&& sound.readyState > 2
					) {
						
						return;
						
					}
					
					sound.play();
					
					this.$safeCookies.set('soundCatcher', Date.now());
					
				} catch (e) {
					
					// nothing
				
				}
				
			}
			
		}
		
	};

</script>

<style lang="scss">
	
	@import "../assets/scss/styles";
	
	.#{$namespace}-bubbles {
		display: inline-block;
		height: 0.9em;
		width: 1.9em;
		position: relative;
		
		&:after {
			content: " ";
			position: absolute;
			top: -0.45em;
			display: inline-block;
			height: 25px;
			width: 25px;
			background: url('#{$s3Endpoint}/chat-bubbles.gif') no-repeat center transparent;
			background-size: contain;
		}
	}
	
	.#{$namespace}.#{$namespace}-flashing .#{$namespace}-bubbles {
		
		&:after {
			background-image: url('#{$s3Endpoint}/chat-bubbles-alert-animated.gif');
		}
		
	}
	
	.#{$namespace}-close-spinner {
		margin: 0.15em 0.2em 0.2em 0.2em !important;
	}
	
	.#{$namespace}-top-alerts {
		position: relative;
		overflow: hidden;
		
		.#{$namespace}-top-alerts-inner.with-margin {
			margin-bottom: 15px;
		}
		
	}

</style>
