<template>
	
	<i :class="classObj" :aria-label="ariaLabel" :title="title" />
	
</template>

<script>
	
	export default {
		
		props: {
			
			name: {
				type: String,
				required: true
			},
			
			label: {
				type: String,
				default: ''
			}
			
		},
		
		computed: {
			
			classObj() {
				
				return [
					'pllenty-chat-icomoon',
					'pllenty-chat-icomoon-' + this.name
				];
				
			},
			
			ariaLabel() {
				
				return (this.label.length) ? this.label : 'false';
				
			},
			
			title() {
				
				return this.label;
				
			}
			
		}
		
	};

</script>