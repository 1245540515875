let flashing, flashTimeout, originalTitle;

let altTitles = [
	'►▷ New Chat Message! ◁◄',
	'▂ ▃ ▅ ▆ █ ▆ ▅ ▃ ▂',
	'╚▶ New Chat Message ◀╝'
];

function emitStop(el) {
	
	let event = new CustomEvent('stop-flashing');
	el.dispatchEvent(event);
	
}

function flash(el, onlyOnce) {
	
	if (flashing) {
		
		return;
		
	}
	
	flashing = true;
	
	originalTitle = document.title;
	
	if (altTitles && typeof altTitles === "object") {
		
		document.title = altTitles[Object.keys(altTitles)[0]];
		
	} else {
		
		document.title = altTitles;
		
	}
	
	// originalTransition = el.style.transition;
	// el.style.transition = 'none';
	
	el.classList.add('pllenty-chat-flashing');
	el.classList.add('pllenty-chat-flashing-on'); // fix slow start
	
	let turnOn = 0;
	
	flashTimeout = setInterval(() => {
		
		el.classList.add('pllenty-chat-flashing');
		
		if (turnOn) {
			
			el.classList.remove('pllenty-chat-flashing-off');
			el.classList.add('pllenty-chat-flashing-on');
			
		} else {
			
			el.classList.remove('pllenty-chat-flashing-on');
			el.classList.add('pllenty-chat-flashing-off');
			
		}
		
		turnOn = !turnOn;
		
		if (altTitles && typeof altTitles === "object") {
			
			let titleUsed = altTitles.indexOf(document.title);
			
			if (titleUsed === -1) {
				
				let firstKey = Object.keys(altTitles)[0];
				document.title = altTitles[firstKey];
				
			} else if (titleUsed === (altTitles.length - 1)) {
				
				document.title = originalTitle;
				
			} else {
				
				let nextKey = Object.keys(altTitles)[titleUsed + 1];
				document.title = altTitles[nextKey];
				
			}
			
		} else if (altTitles) {
			
			document.title = (document.title === originalTitle) ? altTitles : originalTitle;
			
		}
		
		if (onlyOnce) {
			
			setTimeout(emitStop.bind(null, el), 1600); // 1600 for background to have time to flash twice
			
		}
		
	}, 800);
	
}

function stopFlashing(el) {
	
	clearTimeout(flashTimeout);
	
	let classList = el.classList;
	classList.remove('pllenty-chat-flashing');
	classList.remove('pllenty-chat-flashing-on');
	classList.remove('pllenty-chat-flashing-off');
	
	flashing = flashTimeout = false;
	
	document.title = originalTitle;
	
}

export default {
	
	bind: (el) => {
		
		originalTitle = document.title;
		
		/*
		// or stop on focus of chat input
		window.addEventListener("focus", () => {
			
			if (flashing) {
				
				emitStop(el);
				
			}
			
		});*/
		
	},
	
	update: (el, binding, vnode) => {
		
		if (vnode.context[binding.expression] === 'ONCE') {
			
			flash(el, true);
			
		} else if (vnode.context[binding.expression]) {
			
			flash(el);
			
		} else {
			
			stopFlashing(el);
			
		}
		
	}
	
};
