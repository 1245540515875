<template>
	
	<layout-div
		ref="content-scroll"
		v-bar="vuebarOptions"
		v-bar-scrollable="scrollableCallback"
		name="content-scroll"
		:style="styleObj"
	>
		
		<div>
			
			<slot></slot>
			
		</div>
		
	</layout-div>

</template>

<script>
	
	import * as Directives from '../../elements/directives';
	import LayoutDiv from "./LayoutDiv";
	
	export default {
		
		components: {
			LayoutDiv
		},
		
		directives: {
			...Directives
		},
		
		props: {
			scrollStyle: {
				type: String,
				default: 'default',
				validator: val => ['default', 'chat'].indexOf(val) !== -1
			}
		},
	
		data: () => {
			
			return {
				
				calculatedHeight: null,
				rootElHeightSaved: null,
				substractedElHeights: {},
				
				vuebarOptions: {
					preventParentScroll: true,
					el1Class: 'pllenty-chat-vb',
					el1ScrollVisibleClass: 'pllenty-chat-vb-visible',
					el1ScrollInvisibleClass: 'pllenty-chat-vb-invisible',
					el1ScrollingClass: 'pllenty-chat-vb-scrolling',
					el1ScrollingPhantomClass: 'pllenty-chat-vb-scrolling-phantom',
					el1DraggingClass: 'pllenty-chat-vb-dragging',
					el1DraggingPhantomClass: 'pllenty-chat-vb-dragging-phantom',
					el2Class: 'pllenty-chat-vb-content',
					draggerClass: 'pllenty-chat-vb-dragger',
					draggerStylerClass: 'pllenty-chat-vb-dragger-styler'
				}
				
			};
			
		},
		
		computed: {
			
			styleObj() {
				
				let obj = {};
				
				if (this.calculatedHeight) {

					obj.height = this.calculatedHeight;
				
				}
				
				return obj;
				
			}
			
		},
		
		mounted() {
			
			this.calculateHeight();
			
			window.addEventListener('resize', this.calculateHeight);
			
			const self = this;
			
			this.$refs['content-scroll'].$el.addEventListener('pllenty-chat-trigger-resize', () => {
				
				self.calculateHeight();
				
			});
			
		},
		
		methods: {
			
			elementsToWatchHeight() {
				
				let elems = [];
				
				if (!this.$el) {
					
					return elems;
					
				}
				
				let rootEl = this.$el.closest('.pllenty-chat');
				let formEl = this.$el.closest('.pllenty-chat-form');
				
				let inRoot = ['header', 'top-alerts'];
				
				[
					'header',
					'top-alerts',
					'inside-alerts',
					'content-header',
					'sub-content'
				].filter(elClass => {
					
					let parentEl = (inRoot.includes(elClass)) ? rootEl : formEl;
					
					let el = parentEl.querySelector('.pllenty-chat-' + elClass);
					
					if (el) {
						
						elems.push(el);
						
					}
					
				});
				
				return elems;
				
			},
			
			calculateHeight() {
				
				if (!this.$el || !this.$el.closest('.pllenty-chat')) {

					return false;
				
				}
				
				let rootElHeight = this.$el.closest('.pllenty-chat').offsetHeight;
				
				let substract = 0;
				
				this.elementsToWatchHeight().forEach(el => {
					
					substract += el.offsetHeight;
					
				});
				
				let h;
				
				if (rootElHeight > 50) {
					
					// save for when collapsed
					this.rootElHeightSaved = rootElHeight;
					h = rootElHeight;
					
				} else {
					
					h = this.rootElHeightSaved;
					
				}
				
				if (!h) {
					
					h = Math.floor(document.documentElement.clientHeight * 0.90);
					
				}
				
				if (h > 600) {
					
					h = 600;
					
				}
				
				this.$set(this.$data, 'calculatedHeight', (h - substract) + 'px');
				
			},
			
			scrollableCallback(evt, el) {
				
				if (this.scrollStyle === 'chat' && evt.type === 'scrollInserted') {
					
					this.$nextTick(() => {
						
						if (this.scrollStyle === 'chat' && evt.type === 'scrollInserted') {
							
							el.scrollTop = el.scrollHeight;
							
						}
						
					});
					
				} else if (evt.type === 'scrollTo') {
					
					el.scrollTo(evt.detail);
					
				}
				
			}
			
		}
		
	};

</script>

<style scoped lang="scss">
	
	* {
		will-change: height;
		transform: translateZ(0);
		backface-visibility: hidden;
		perspective: 1000px;
	}
	
	.#{$namespace}-vb {
		
		/deep/ .#{$namespace}-vb-content {
			box-sizing: border-box !important;
			padding-right: 0 !important;
		}
		
		/deep/ .#{$namespace}-vb-dragger {
			z-index: 5;
			width: 17px;
			right: -2px;
			
			&  > .#{$namespace}-vb-dragger-styler {
				backface-visibility: hidden;
				transform: rotate3d(0,0,0,0);
				transition:
					background-color 100ms ease-out,
					margin 100ms ease-out,
					height 100ms ease-out;
				background-color: rgba(0, 0, 0, .3);
				margin: 5px;
				border-radius: 20px;
				height: calc(100% - 10px);
				display: block;
			}
			
			&:hover > .#{$namespace}-vb-dragger-styler {
				background-color: rgba(0, 0, 0, .5);
			}
			
		}
		
		&.#{$namespace}-vb-scrolling-phantom {
			
			/deep/ .#{$namespace}-vb-dragger > .#{$namespace}-vb-dragger-styler {
				background-color: rgba(0, 0, 0, .3);
			}
			
		}
		
		&.#{$namespace}-vb-dragging {
			
			/deep/ .#{$namespace}-vb-dragger > .#{$namespace}-vb-dragger-styler {
				background-color: rgba(0, 0, 0, .5);
			}
			
		}
		
		&.#{$namespace}-vb-dragging-phantom {
			
			/deep/ .#{$namespace}-vb-dragger > .#{$namespace}-vb-dragger-styler {
				background-color: rgba(0, 0, 0, 0.5);
			}
			
		}
		
	}

</style>
