<template>
	
	<li :class="classes">
		<span class="pllenty-chat-message-user">{{ message.user }}</span>
		<span class="pllenty-chat-message-time">{{ timeAgo | duration('humanize') }} ago</span>
		<p class="pllenty-chat-message-content">{{ message.message /* do not put on new line */ }}</p>
	</li>
	
</template>

<script>
	
	export default {
		
		props: {
			
			message: {
				type: Object,
				required: true
			}
			
		},
		
		computed: {
			
			classes() {
				
				return [
					'pllenty-chat-message',
					'pllenty-chat-message-' + this.message.type.toLowerCase()
				];
				
			},
			
			timeAgo() {
				
				return Date.now() - this.message.created;
				
			}
			
		}
		
	};

</script>

<style scoped lang="scss">
	
	.#{$namespace}-message {
		padding: 10px 16px 10px 10px;
		border-bottom: $list-border;
	}
	
	.#{$namespace}-message-hub .#{$namespace}-message-content {
		color: $hubText;
	}
	
	.#{$namespace}-message-visitor .#{$namespace}-message-content {
		color: $visitorText;
	}
	
	.#{$namespace}-message-system {
		
		.#{$namespace}-message-user {
			display: none;
		}
		
		.#{$namespace}-message-content {
			font-size: 0.8em;
			line-height: 1.2;
			margin: 0;
		}
		
	}
	
	.#{$namespace}-message-user {
		font-size: 0.8em;
		color: lighten($text-color, 60%);
	}
	
	.#{$namespace}-message-time {
		float: right;
		font-size: 0.8em;
		color: lighten($text-color, 60%);
	}
	
	.#{$namespace}-message-content {
		padding: 0;
		margin: 4px 0 0;
		word-break: break-word;
		line-height: 1.4;
		white-space: pre-line;
	}
	
</style>
