export default {
	
	data() {
		
		return {
			alertHeightInterval: {}
		};
		
	},
	
	methods: {
		
		startAlertResize(target) {
			
			let key = target._uid;
			
			this.alertHeightInterval[key] = setInterval(function () {
				
				let scroller = document.querySelector('.pllenty-chat-content-scroll');
				
				if (scroller) {
					
					scroller.dispatchEvent(new CustomEvent('pllenty-chat-trigger-resize'));
					
				}
				
			}, 1);
			
		},
		
		stopAlertResize(target) {
			
			let key = target._uid;
			
			clearInterval(this.alertHeightInterval[key]);
			this.$delete(this.alertHeightInterval, key);
			
			let scroller = document.querySelector('.pllenty-chat-content-scroll');
			
			if (scroller) {
				
				scroller.dispatchEvent(new CustomEvent('pllenty-chat-trigger-resize'));
				
			}
			
		}
		
	}
	
};
