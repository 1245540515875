import { camelCase } from 'lodash';
import axios from 'axios';

const chatS3Folder = axios.create({
	baseURL: process.env.VUE_APP_BASE,
	headers: {
		'Content-Type': 'application/json',
		'Accept': 'application/json'
	}
});

const chatApi = axios.create({
	baseURL: process.env.VUE_APP_API,
	headers: {
		'Content-Type': 'application/json',
		'Accept': 'application/json'
	}
});

const transactionsApi = axios.create({
	baseURL: process.env.VUE_APP_TRANSACTIONS_API,
	headers: {
		'Content-Type': 'application/json',
		'Accept': 'application/json'
	}
});

export const getConfig = (companyId) => {
	
	let promise = chatS3Folder.get(`${companyId}/config.json`);
	
	promise.then(response => {
		
		if (response.data.css) {
			
			let style = document.createElement('style');
			style.innerHTML = response.data.css;
			
			document.querySelector('head').appendChild(style);
		
		}
		
	});
	
	return promise;

};

export const createRequest = (companyId, data) => {
	
	return chatApi.post(`request/${companyId}`, data);

};

export const getInfo = (chatInfo) => {

	let session = chatInfo.session;
	let postData;
	
	if (!session.userId && chatInfo.session.hub && chatInfo.session.hub.id) {

		session.userId = 'temp' + chatInfo.session.hub.id;
	
	}

	if (chatInfo.status === 'Chatting' && !chatInfo.isHubAgent) {
		
		postData = {
			formData: Object.assign({}, chatInfo.formData),
			formShown: chatInfo.formShown,
			typing: Boolean(chatInfo.typing)
		};
		
		['card_num', 'card_year', 'card_month', 'card_cvv', 'card_name'].forEach(key => {
		
			 if (!postData.formData[key]) {

			 	  return;
			 
			}
			
			let everythingButSpacesRegex = /[^\t\n\r ]/gim;
			
			let text = '' + postData.formData[key];
			postData.formData[key] = text.replace(everythingButSpacesRegex, '*');
		
		});
		
	} else {
		
		postData = {
			typing: Boolean(chatInfo.typing)
		};
		
	}
	
	if (chatInfo.session.hub) {

		postData.hub = chatInfo.session.hub;
	
	}
	
	return chatApi.post(`channel/${session.id}/${session.userId}`, postData);

};

export const addMessage = (session, data) => {
	
	return chatApi.post(`messages/${session.id}/${session.userId}`, data);

};

export const addSystemMessage = (session, data) => {
	
	return chatApi.post(`messages/${session.id}`, data);
	
};

export const addUser = (session, message = null) => {
	
	let data = {
		...session.hub,
		message
	};
	
	return chatApi.post(`users/${session.id}`, data);
	
};

export const userQuit = chatInfo => {
	
	if (/^temp\d+$/.test(chatInfo.session.userId)) {
		
		return new Promise((resolve, reject) => {

			// console.log('Without a user id, you can\'t quit the chat session...');
			resolve();
		
		});
		
	}
	
	return chatApi.post(`users/${chatInfo.session.id}/${chatInfo.session.userId}/quit`);
	
};

export const postTransaction = (chatInfo, formData) => {
	
	let data = {};
	
	let formFields = [
		'accountNum', // 'edpNumber',
		'first_name', // 'firstName',
		'last_name', // 'lastName',
		'email', // 'email',
		'phone', // 'phone',
		'address_1', // 'address',
		'address_2', // 'suite',
		'city',
		'region', // 'province',
		'postal_code', // 'postalCode',
		'card_name', // 'cardHolder',
		'card_month',// 'cardMonth',
		'card_year', // 'cardYear',
		'card_cvv', // 'cardCvv',
		'card_num', // 'cardNumber',
		'amount'
	];
	
	formFields.map(formField => {
		
		let camelCased = camelCase(formField);
		
		if (formData[formField]) {
			
			let val = formData[formField];
			
			if (typeof val === 'object') {

				val = val.value;
			
			}
			
			data[formField] = val;
			
		} else if (chatInfo.user[camelCased]) {
			
			data[formField] = chatInfo.user[camelCased];
			
		} else {
			
			data[formField] = '';
			
		}
		
	});
	
	return chatApi.post(`transaction/${chatInfo.session.id}/${chatInfo.session.userId}`, data);
	
};

export const closeChat = (session, data) => {
	
	return chatApi.post(`channel/${session.id}/${session.userId}/close`, data);

};
