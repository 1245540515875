<template>
	
	<div class="select-wrapper">
		
		<!--<div aria-hidden="true" v-if="fieldType === 'select'" style="position: absolute; z-index: -1; opacity: 0;">
			<label :for="name">{{ field.label }}</label>
			<input
				type="text"
				:name="'hidden-' + name"
				:autocomplete="field.autocomplete"
				:x-autocompletetype="field['x-autocompletetype'] || field.autocomplete"
				v-model="value[name]"
				ref="selectHidden"
				tabindex="-1"
			/>
		</div>-->
		
		<label v-if="label" :for="name">{{ label }}</label>
		
		<div :class="divClasses">
			
			<label
				v-if="placeholder"
				:for="name"
				:class="placeholderClasses"
				:aria-hidden="(label)"
			>{{ placeholder }}</label>
			
			<!--<input
				type="text"
				v-model="vModel"
				v-bind="selectAttr"
			/>-->
			
			<select
				ref="select"
				v-bind="selectAttr"
				:value="value"
				@input="$emit('input', $event.target.value)"
				@focus="inFocus = true"
				@blur="inFocus = false"
			>
				<option class="option empty">{{ empty }}</option>
				<option v-for="option in options" class="option" :value="option.value">{{ option.text }}</option>
			</select>
			
		</div>
	
	</div>

</template>

<script>
	
	export default {
		
		name: 'CustomSelect',
		
		inheritAttrs: false,
		
		props: {
			name: {
				type: String,
				required: true
			},
			value: {
				type: [String, Number, Object, Boolean],
				default: null
			},
			options: {
				type: Array,
				default: () => []
			},
			empty: {
				type: [String, null],
				default: null
			},
			label: {
				type: [String, null],
				default: null
			},
			placeholder: {
				type: [String, null],
				default: null
			}
		},
		
		data() {
			
			return {
				vModel: null,
				selectAttr: {},
				inFocus: false
			};
			
		},
		
		computed: {
			
			divClasses() {
				
				return {
					'select-div': 1,
					'select-div-in-focus': this.inFocus
				};
				
			},
			
			selectClasses() {
				
				return {
					'select': 1,
					'select-when-empty': (!this.value),
					'select-when-val': (this.value)
				};
				
			},
			
			placeholderClasses() {
				
				return {
					'placeholder': 1,
					'placeholder-when-empty': (!this.value),
					'placeholder-when-val': (this.value)
				};
				
			}
			
		},
		
		watch: {
			
			$attrs: {
				handler(val) {
					
					// instead of in mounted, because of immediate being true, to keep everything together
					
					if (typeof this.selectAttr.class == 'undefined') {
						
						this.$set(this.selectAttr, 'class', this.selectClasses);
						
					}
					
					let bad = [];
					
					Object.keys(val).forEach(attrName => {
						
						if (
							!bad.includes(attrName)
							&& this.selectAttr[attrName] !== val[attrName]
						) {
							
							this.$set(this.selectAttr, attrName, val[attrName]);
							
						}
						
					});
					
				},
				immediate: true
			}
			
		}
		
	};

</script>

<style scoped lang="scss">
	
	@import '@/icomoon/variables.scss';
	
	.select-div {
		position: relative;
		display: block;
		width: 100%;
		height: auto;
		background: $input-bg;
		color: $input-color;
		font-size: $input-font-size;
		border: $input-border;
		border-radius: $input-border-radius;
		box-shadow: $input-shadow;
		
		&:after {
			z-index: 2;
			position: absolute;
			right: 0.25em;
			top: 0.15em;
			font-size: 1.4em;
			content: $pllenty-chat-icomoon-chevron-down;
			/* use !important to prevent issues with browser extensions that change fonts */
			font-family: '#{$icomoon-font-family}' !important;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			
			/* Better Font Rendering =========== */
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}
		
	}
	
	.select-div-in-focus {
		border-color: $input-focus-border-color;
	}
	
	.placeholder {
		z-index: 0;
		position: absolute;
		padding: $input-padding;
		color: $input-placeholder-color;
		font-size: $input-font-size;
		touch-action: none;
		user-select: none;
		max-width: 85%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		
		&.placeholder-when-val {
			opacity: 0;
		}
	}
	
	.select {
		appearance: none;
		border: none;
		background: none;
		box-shadow: none;
		position: relative;
		z-index: 1;
		display: block;
		width: 100%;
		height: auto;
		margin: 0;
		padding: $input-padding;
		color: $input-color;
		font-size: $input-font-size;
		
		&:focus, &:active {
			border-color: $input-focus-border-color;
		}
		
		&::-ms-expand {
			display: none;
		}
		
	}
	
	.option {
		color: $input-color;
		font-size: $input-font-size;
		background: #FFF;
		
		&.empty {
			color: $input-placeholder-color;
		}
	}

</style>
