let scrollTimeout, scrollToTimeout;

export default {
	
	inserted: (el, binding, vnode) => {
		
		let vueBar = el.querySelector('.pllenty-chat-vb-content');
		
		vueBar.scrollTo = function (pos) {
			
			let scrollTopVal;
			
			if (/^bottom/.test(pos.toLowerCase())) {
				
				scrollTopVal = vueBar.scrollHeight;
				
			} else if (/^top/.test(pos.toLowerCase())) {
				
				scrollTopVal = 0;
				
			} else {
				
				scrollTopVal = parseFloat(pos);
				
			}
			
			this.scrollTop = scrollTopVal;
			
			return scrollTopVal;
			
		}.bind(vueBar);
		
		const scrollFn = evt => {
			
			clearTimeout(scrollTimeout);
			
			scrollTimeout = setTimeout(() => {
				
				if (binding.value(evt, vueBar)) {
					
					vueBar.removeEventListener('scroll', scrollFn);
					
				}
				
			}, 250);
			
		};
		
		const scrollToFn = (evt) => {
			
			clearTimeout(scrollToTimeout);
			
			scrollToTimeout = setTimeout(() => {
				
				if (binding.value(evt, vueBar)) {
					
					vueBar.removeEventListener('scrollTo', scrollToFn);
					
				}
				
			}, 250);
			
		};
		
		// callback when inserted
		
		let evt = new CustomEvent('scrollInserted');
		
		binding.value(evt, vueBar);
		
		// callback when scrolling
		
		vueBar.addEventListener('scroll', scrollFn);
		vueBar.addEventListener('scrollTo', scrollToFn);
		
	}
	
};
