<template>
	
	<form name="pllenty-chat-invitation" class="pllenty-chat-form pllenty-chat-form-invitation">
		
		<layout-div name="content">
			
			<layout-div name="content-header">
				
				<div class="pllenty-chat-form-field pllenty-chat-form-field-search">
					<input v-model="searchQ" type="text" />
				</div>
				
			</layout-div>
			
			<scroller>
				
				<ul class="pllenty-chat-list pllenty-chat-list-users">
					
					<li v-for="user in filteredUsers">
						<a href="javascript:;" :class="{'pllenty-chat-disabled': userInChannel(user)}" @click.prevent="inviteUser(user)">
							
							<!-- eslint-disable vue/no-v-html -->
							<span class="pllenty-chat-user-name" v-html="userSearchedValues(user, 'name')"></span>
							<span class="pllenty-chat-user-email" v-html="userSearchedValues(user, 'email')"></span>
							<!-- eslint-enable vue/no-v-html -->
							
							<icon v-if="userInChannel(user)" name="check" />
							<spinner v-else-if="isUserBeingAdded(user)" :size="1.4" />
							<icon v-else name="user-add" />
							
						</a>
					</li>
					
				</ul>
			
			</scroller>
		
		</layout-div>
		
		<layout-div name="sub-content">
		
		</layout-div>
	
	</form>

</template>

<script>
	
	import sanitizeHtml from 'sanitize-html';
	import { Icon, LayoutDiv, Scroller, Spinner } from '../../elements/components';
	import { addUser } from '../../api';
	
	export default {
		
		components: {
			Icon,
			LayoutDiv,
			Scroller,
			Spinner
		},
		
		filters: {
			
			showSearch(val, replace) {
				
				let regex = new RegExp('(' + replace + ')', 'i');
				
				val = sanitizeHtml(val, {
					allowedTags: [],
					allowedAttributes: {}
				});
				
				return val.replace(regex, '<mark class="pllenty-chat-highlight">$1</mark>');
				
			}
			
		},
		
		props: {
			
			chatInfo: {
				type: Object,
				required: true
			},
			
			users: {
				type: Array,
				default: () => {}
			}
			
		},
		
		data() {
			
			return {
				searchQ: '',
				errorMessage: null,
				usersBeingAdded: []
			};
			
		},
		
		computed: {
			
			filteredUsers() {
				
				if (!this.users || !Object.keys(this.users).length) {
					
					return [];
					
				}
				
				return Object.values(this.users).filter(user => {
					
					let bool = false;
					
					['email', 'firstName', 'lastName'].forEach(item => {
						
						if (user[item] && user[item].toLowerCase().includes(this.searchQ.toLowerCase())) {

							bool = true;
						
						}
						
					});
					
					return bool;
					
				});
				
			}
			
		},
		
		methods: {
			
			userSearchedValues(user, value) {
				
				if (value === 'name') {

					return this.$options.filters.showSearch(this.extractName(user), this.searchQ);
				
				}
				
				return this.$options.filters.showSearch(user[value], this.searchQ);
				
			},
			
			userInChannel(user) {
				
				if (!this.chatInfo || !this.chatInfo.users || !Object.values(this.chatInfo.users).length) {
					
					return [];
					
				}
				
				return Object.values(this.chatInfo.users).find(item => {
					
					if (item.email === user.email && item.active) {
						
						return item;
						
					}
					
				});
				
			},
			
			isUserBeingAdded(user) {
				
				return this.usersBeingAdded.find(item => {
					
					if (item.email === user.email) {

						return item;
					
					}
					
				});
				
			},
			
			extractName(user) {
				
				return [user.firstName, user.lastName].join(' ').trim();
				
			},
			
			inviteUser(user) {
				
				if (this.userInChannel(user) || this.isUserBeingAdded(user)) {

					return;
				
				}
				
				this.usersBeingAdded.push(user);
				
				let data = Object.assign({}, this.chatInfo.session, {
					hub: user
				});
				
				addUser(data)
					.then(res => {
					
						// console.log('[inviteUser] addUser', res);
						//user.adding = false;
						//user.added = true;
					
					})
					.catch(err => {
						
						//user.adding = false;
						this.errorMessage = err.response.data.errorMessage.en;
						
					});
				
			}
			
		}
		
	};

</script>

<style scoped lang="scss">
	
	.#{$namespace}-content-header {
		position: relative;
		z-index: 1;
		padding-top: 4px;
		
	}
	
	.#{$namespace}-content {
		border-radius: $border-radius;
	}
	
	.#{$namespace}-sub-content {
		padding-top: 2px;
	}
	
	.#{$namespace}-list > li {
		& > a {
			display: block;
			padding: 10px;
			padding-right: 3.3em;
			border-bottom: $list-border;
			text-decoration: none;
			position: relative;
			
			&:hover, &:focus {
				background: $highlighting-lighter;
			}
			
			.pllenty-chat-icomoon {
				position: absolute;
				top: 10px;
				right: 10px;
				font-size: 2em;
				color: lighten($text-color, 60%);
			}
			
			.pllenty-chat-icomoon-user-add {
				top: 10px;
			}
			
			.#{$namespace}-spinner-div {
				position: absolute;
				top: 15px;
				right: 13px;
				color: lighten($text-color, 60%);
			}
			
		}
	}
	
	.#{$namespace}-form-field {
		padding: 10px;
		margin: 0;
	}
	
	.#{$namespace}-user-email {
		display: block;
		font-size: 0.8em;
		color: lighten($text-color, 60%);
	}
	
	/deep/ .#{$namespace}-highlight {
		background: $highlighting-light;
		display: inline-block;
		font-size: inherit;
		font-weight: inherit;
		color: inherit;
	}

</style>
