<template>
	
	<layout-div name="content-header">
		
		<p>
			
			<span class="pllenty-chat-single-line-text">
				
				<template v-if="chatInfo.isHubAgent">
					{{ visitors[0] }}<template v-if="agents.length"> (Visitor)</template>
				</template>
				
				<template v-else>{{ agents.join(', ') }}</template>
				
			</span>
			
			<small>
				
				<template v-if="chatInfo.isHubAgent">
					
					<template v-if="!agents.length">Visitor</template>
					
					<span v-else :title="agents.join(', ')">
						and
						<template v-if="agents.length === 1">{{ agents[0] }}</template>
						<template v-else>{{ agents.length }} Support Agents</template>
					</span>
					
				</template>
				
				<template v-else>{{ agentsWithCount }}</template>
				
			</small>
			
		</p>
		
	</layout-div>
	
</template>

<script>
	
	import { LayoutDiv } from '../../elements/components';
	
	export default {
		
		components: {
			LayoutDiv
		},
		
		props: {
			
			chatInfo: {
				type: Object,
				required: true,
				sync: true
			}
			
		},
		
		data() {
			
			return {
				agents: [],
				visitors: []
			};
			
		},
		
		computed: {
			
			agentsWithCount() {
				
				return (this.agents.length > 1) ? this.agents.length + ' Support Agents' : 'Support Agent';
				
			}
			
		},
		
		watch: {
			
			chatInfo: {
				handler(val) {
					
					if (!val || !val.users) {

						return;
					
					}
					
					let __self = this;
					
					// remove missing/inactive users
					
					if (__self.agents && __self.agents.length) {
						
						__self.agents.forEach(name => {
							
							let index = Object.keys(val.users).find(i => (__self.extractName(val.users[i]) === name));
							
							if (!index || !val.users[index].active || val.users[index].active === 'false') {
								
								let index = __self.agents.indexOf(name);
								
								if (index !== -1) {

									__self.agents.splice(index, 1);
								
								}
								
							}
							
						});
						
					}
					
					// add new users or remove if status is not active
					
					if (val.users && Object.keys(val.users).length) {
						
						Object.keys(val.users).forEach(i => {
							
							let user = val.users[i];
							let name = this.extractName(user);
							
							if (
								!user.active
								|| user.active === 'false'
								|| (this.chatInfo.isHubAgent && this.chatInfo.session.hub && this.chatInfo.session.hub.email === user.email)
							) {

								return;
							
							}
							
							if (user.type === 'Visitor') {
								
								if (__self.visitors.indexOf(name) === -1) {

									__self.visitors.push(name);
								
								}
								
							} else if (user.type === 'Hub') {
								
								if (__self.agents.indexOf(name) === -1) {
									
									__self.agents.push(name);
									
								}
								
							}
							
						});
					
					}
					
				},
				deep: true,
				immediate: true
			}
			
		},
		
		methods: {
			
			extractName: user => [user.firstName, user.lastName].join(' ').trim(),
			
		}
		
	};

</script>

<style scoped lang="scss">

</style>