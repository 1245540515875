<template>
	
	<form
		name="pllenty-chat-chat-form"
		class="pllenty-chat-form pllenty-chat-chat-form"
		@submit.prevent="sendMessage"
	>
		
		<layout-div name="content" :class="contentClasses">
			
			<users :chat-info="chatInfo" />
			
			<scroller scroll-style="chat">
				
				<div :class="scrollInnerClasses">
					
					<ul class="pllenty-chat-list pllenty-chat-message-list">
						
						<connecting v-if="connecting && !chatInfo.messages.length" :chat-info="chatInfo" />
						
						<message v-for="(message, i) in chatInfo.messages" :key="i" :message="message" />
						
					</ul>
					
					<p class="pllenty-chat-typing-indicator" :style="typingStyleObj"></p>
					
				</div>
				
				<alert v-model="errorMessage" type="error" />
				
			</scroller>
			
		</layout-div>
		
		<layout-div name="sub-content">
			
			<div v-if="enabled" class="pllenty-chat-input pllenty-chat-textarea pllenty-chat-textarea-with-btn">
				
				<textarea
					v-model="myMessage"
					name="pllenty-chat-input"
					:placeholder="(!userIsInChannel) ? 'Type here to join the channel' : 'Type here'"
					:disabled="!enabled || sendingMessage"
					@keypress="onKeyPress"
					@keyup="onKeyUp"
					@blur="onBlur"
					@focus="onFocus"
					@send-shortcut="onSendShortcut"
				></textarea>
				
				<button :disabled="!enabled || sendingMessage" class="pllenty-chat-btn pllenty-chat-send-btn" title="Send">
					<icon v-if="!sendingMessage" name="paper-plane" label="Send" />
					<spinner v-else :size="1"></spinner>
				</button>
				
			</div>
			
			<div v-else-if="chatInfo.status === 'Closed'">
				<h3>The chat session has ended.</h3>
			</div>
			
		</layout-div>
		
	</form>
	
</template>

<script>
	
	import { Alert, Icon, LayoutDiv, Scroller, Spinner } from '../../elements/components';
	import Users from './Users';
	import Message from './Message';
	import { addMessage, addUser } from '../../api';
	import Connecting from '../../request/components/Connecting';
	
	export default {
	
		components: {
			Alert,
			Connecting,
			Icon,
			LayoutDiv,
			Message,
			Scroller,
			Spinner,
			Users
		},
		
		props: {
			
			chatInfo: {
				type: Object,
				required: true
			},
			
			enabled: {
				type: Boolean,
				default: false
			},
			
			userIsInChannel: {
				type: Boolean,
				default: false
			}
			
		},
		
		data() {
	
			return {
				errorMessage: null,
				myMessage: '',
				sendingMessage: false,
				connecting: false
			};
		
		},
		
		computed: {
			
			contentClasses() {
				
				return {
					'pllenty-chat-disabled': !this.userIsInChannel,
					'pllenty-chat-form-is-shown': this.chatInfo.isHubAgent && this.chatInfo.formShown,
					'pllenty-chat-form-not-shown': this.chatInfo.isHubAgent && !this.chatInfo.formShown
				};
				
			},
			
			scrollInnerClasses() {
				
				return {
					'pllenty-chat-with-alert': this.errorMessage
				};
				
			},
			
			typingStyleObj() {
				
				return {
					opacity: this.chatInfo.someoneIsTyping ? 1 : 0
				};
				
			}
			
		},
		
		watch: {
			
			chatInfo: {
				handler(val) {
					
					// console.log('[ChatPane] chatInfo', val);
					
					if (val && val.status === 'Requested') {
						
						return this.connecting = true;
						
					}
					
					this.connecting = false;
					
				},
				deep: true,
				immediate: true
			}
			
		},
	
		methods: {
			
			onKeyUp(event) {
				
				if (!this.enabled) {
					
					return;
				
				}
				
				if (event.key === 'Backspace' && !event.target.value) {
					
					this.chatInfo.typing = 0;
					
				}
				
			},
			
			onKeyPress(event) {
				
				if (!this.enabled) {
					
					return;
					
				}
				
				if (event.key === 'Enter' && !event.shiftKey) {
					
					this.sendMessage();
					event.preventDefault();
					
				} else if (event.key === 'Backspace' && !event.target.value) {
					
					this.chatInfo.typing = 0;
					
				} else {
					
					this.chatInfo.typing = 1;
					
				}
				
			},
			
			setErrorMessage(err) {
				
				this.errorMessage = err.response.data.errorMessage;
				
				setTimeout(() => {
					
					let evt = new CustomEvent('scrollTo', {detail: 'bottom'});
					this.$el.querySelector('.pllenty-chat-vb-content').dispatchEvent(evt);
					
				}, 300);
				
			},
			
			addUser() {
				
				if (!this.enabled) {
					
					return;
					
				}
				
				addUser(this.chatInfo.session, this.myMessage)
					.then(res => {
						
						this.chatInfo.session.userId = res.data.userId;
						this.$emit('saveUserId', this.chatInfo.session.userId);
						
						if (res.data.index && res.data.message) {
							
							this.$emit('addMessage', {i: res.data.index, message: res.data.message});
							this.myMessage = '';
							
						}
						
					})
					.catch(this.setErrorMessage.bind(this))
					.finally(() => {
						
						this.sendingMessage = false;
						this.chatInfo.typing = 0;
						
					});
			
			},
			
			sendMessage() {
	
				if (!this.enabled || this.sendingMessage || !this.myMessage) {

					return;
				
				}
	
				this.sendingMessage = true;
				
				if (this.chatInfo.session.hub && /^temp\d+/.test(this.chatInfo.session.userId)) {
					
					return this.addUser();
					
				}
				
				addMessage(this.chatInfo.session, {message: this.myMessage})
					.then(res => {
						
						this.$emit('addMessage', {i: res.data.index, message: res.data.message});
						this.myMessage = '';
					
					})
					.catch(this.setErrorMessage)
					.finally(() => {
						
						this.sendingMessage = false;
						this.chatInfo.typing = 0;
						
					});
			
			},
			
			onFocus() {
				
				let event = new CustomEvent('stop-flashing');
				this.$root.$el.dispatchEvent(event);
				
			},
			
			onBlur() {
				
				this.chatInfo.typing = 0;
				
			},
			
			onSendShortcut(event) {
				
				if (!event.detail || !event.detail.message) {
					
					throw new Error('Could not read the shortcut.');
					
				}
				
				this.myMessage = event.detail.message;
				
				this.sendMessage();
				
			}
			
		}
		
	};

</script>

<style scoped lang="scss">
	
	.#{$namespace}-form-not-shown {
		box-shadow: 0 0 5px $highlighting;
	}
	
	.#{$namespace}-form-is-shown, .#{$namespace}-form-not-shown {
		margin-top: 2px;
	}
	
	/deep/ .#{$namespace}-with-alert {
		transition: padding-bottom 500ms linear;
		padding-bottom: 36px;
	}
	
	/deep/ .#{$namespace}-alert {
		margin-bottom: 0 !important;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
	}
	
	@keyframes #{$namespace}-ellipsis-animation {
		to {
			width: 1.1em;
		}
	}
	
	.#{$namespace}-typing-indicator {
		padding: 0 10px;
		background: #FFFFFF;
		
		&:after {
			content: $typing-indicator-content;
			font-size: 3em;
			color: $typing-indicator;
			overflow: hidden;
			display: inline-block;
			width: 0.05px;
			height: 1em;
			margin: -15px 0 15px;
			animation: #{$namespace}-ellipsis-animation steps(4, end) 1s infinite;
		}
		
	}

</style>
