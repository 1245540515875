<template>
	
	<layout-div name="sub-content">
		
		<div :class="'pllenty-chat-' + btn + '-btn-wrapper'">
			
			<div class="pllenty-chat-btn-wrapper">
				
				<button
					type="submit"
					class="pllenty-chat-btn pllenty-chat-main-btn"
					:class="'pllenty-chat-' + btn + '-btn'"
					:disabled="disabled"
				>
					<slot></slot>
				</button>
			
			</div>
		
		</div>
	
	</layout-div>

</template>

<script>
	
	import { LayoutDiv } from '../../elements/components';
	
	export default {
		
		components: {
			LayoutDiv
		},
		
		props: {
			btn: {
				type: String,
				default: 'default'
			},
			disabled: {
				type: Boolean,
				default: false
			}
		}
		
	};

</script>

<style scoped lang="scss">

</style>
