<template>
	
	<transition-height
		ref="alert-wrapper"
		@enter="$emit('enter', $refs['alert-wrapper'])"
		@after-enter="$emit('after-enter', $refs['alert-wrapper'])"
		@leave="$emit('leave', $refs['alert-wrapper'])"
		@after-leave="$emit('after-leave', $refs['alert-wrapper'])"
	>
		
		<div v-if="value">
			
			<div :class="'pllenty-chat-alert pllenty-chat-alert-' + type" role="alert" @click="hideAlert">
				<icon name="cross" label="Close" />
				{{ value }}
			</div>
			
		</div>
		
	</transition-height>
	
</template>

<script>
	
	import TransitionHeight from './TransitionHeight';
	import Icon from './Icon';
	
	export default {
		
		components: {
			TransitionHeight,
			Icon
		},
		
		props: {
			
			value: {
				type: String,
				default: ''
			},
			
			type: {
				type: String,
				default: 'error',
				validator: val => ['info', 'error'].indexOf(val) !== -1
			},
			
			autoClose: {
				type: [Boolean, String],
				default: false
			}
			
		},
	
		data: () => {
	
			return {
				fadeTimer: null
			};
		
		},
	
		watch: {
			
			value: function (val) {
	
				if (!val || !this.autoClose) {

					return;
				
				}
	
				let _self = this;
	
				if (this.fadeTimer) {
	
					clearTimeout(this.fadeTimer);
				
				}
	
				this.fadeTimer = setTimeout(function () {
	
					_self.hideAlert();
				
				}, 5000);
			
			}
			
		},
		
		methods: {
			
			hideAlert() {
				
				if (this.fadeTimer) {
					
					clearTimeout(this.fadeTimer);
					
				}
				
				this.$emit('input', null);
				
			}
			
		},
		
	};

</script>

<style scoped lang="scss">
	
	.#{$namespace}-alert {
		box-sizing: border-box;
		box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2) inset, 1px 1px 5px rgba(255, 255, 255, 0.6);
		padding: 10px;
		margin-top: 0;
		cursor: pointer;
		
		.#{$namespace}-alert-container {
			color: inherit;
		}
		
	}
	
	.#{$namespace}-icomoon-cross {
		float: right;
	}
	
	.#{$namespace}-alert-error {
		background-color: rgb(184, 76, 76);
		color: #FFFFFF;
		
		&:hover {
			.#{$namespace}-icomoon-cross {
				color: #FFFFFF;
			}
		}
	}
	
	.#{$namespace}-alert-info {
		background-color: #d9edf7;
		color: #31708f;
	}

</style>
