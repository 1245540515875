<template>
	
	<li class="pllenty-chat-message pllenty-chat-message-system">
		<span class="pllenty-chat-message-time">{{ timeAgo | duration('humanize') }} ago</span>
		<p class="pllenty-chat-message-content">{{ message.message }}</p>
	</li>
	
</template>

<script>
	
	export default {
		
		props: {
			
			message: {
				type: Object,
				required: true
			}
			
		},
		
		computed: {
			
			timeAgo() {
				
				return Date.now() - this.message.created;
				
			}
			
		}
		
	};

</script>

<style scoped lang="scss">
	
	.#{$namespace}-message {
		padding: 10px 16px 10px 10px;
		border-bottom: $list-border;
	}
	
	.#{$namespace}-message-user {
		font-size: 0.8em;
		color: lighten($text-color, 60%);
	}
	
	.#{$namespace}-message-time {
		float: right;
		font-size: 0.8em;
		color: lighten($text-color, 60%);
	}
	
	.#{$namespace}-message-content {
		padding: 0;
		margin: 4px 0 0;
		word-break: break-word;
		line-height: 1.4;
		white-space: pre-line;
	}
	
</style>
