<template>
	
	<span class="pllenty-chat-spinner-div" :style="divStyle">
		<icon name="spinner8" class="pllenty-chat-spinner" />
	</span>
	
</template>

<script>
	
	import Icon from './Icon';
	
	export default {
		
		components: {
			Icon
		},
		
		props: {
			
			size: {
				type: [Number, String],
				default: 1
			}
			
		},
		
		computed: {
			
			divStyle() {
				
				return {
					'font-size': this.size + 'em'
				};
				
			}
			
		}
		
	};

</script>

<style scoped lang="scss">
	
	.#{$namespace}-spinner-div {
		position: relative;
		display: inline-block;
		width: 1em;
		height: 1em;
		padding: 0;
		margin: 0;
		font-size: 1rem;
		color: lighten($text-color, 60%);
	}
	
	@keyframes anim-rotate {
		0% {
			transform: rotate(0);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	
	.#{$namespace}-spinner {
		position: absolute;
		top: 0;
		left: 0;
		display: inline-block;
		font-size: 1em !important;
		line-height: 1em !important;
		height: 1em !important;
		margin: 0 !important;
		padding: 0 !important;
		color: inherit !important;
		text-shadow: 0 0 .25em rgba(255,255,255, .3);
		animation: anim-rotate 1s infinite linear;
		cursor: wait !important;
		
		&:hover, &:focus, &:active {
			color: inherit !important;
		}
	}
	
</style>
